import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CreateWebhookRequest,
  DeleteWebhookRequest,
  ListWebhooksRequest,
  UpdateWebhookRequest,
} from 'protos/pb/v1alpha2/webhook_service';
import { webhookService } from '../../services/WebhookService';
import {
  createWebhooks,
  deleteWebhooks,
  fetchWebhooks,
  setCreateWebhookError,
  setCreateWebhookLoading,
  setDeleteWebhookError,
  setDeleteWebhookLoading,
  setUpdateWebhookError,
  setUpdateWebhookLoading,
  setWebhooks,
  setWebhooksListError,
  setWebhooksListLoading,
  updateWebhooks,
} from '../slices/webhooks.slice';
import { DataLoadingStatus } from '../../utils/constants';

export function* listWebhooksSaga(data: {
  type: typeof fetchWebhooks.type;
  payload: ListWebhooksRequest;
}) {
  try {
    yield put(setWebhooksListLoading(DataLoadingStatus.LOADING));
    const { response, error } = yield call(
      webhookService.getWebhookList,
      data.payload,
    );
    if (response) {
      yield put(setWebhooks(response));
      yield put(setWebhooksListLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setWebhooksListError(error?.message || error));
      yield put(setWebhooksListLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setWebhooksListError(error?.message || error));
    yield put(setWebhooksListLoading(DataLoadingStatus.ERROR));
  }
}

export function* updateWebhookSaga(data: {
  type: typeof updateWebhooks.type;
  payload: UpdateWebhookRequest;
}) {
  try {
    yield put(setUpdateWebhookLoading(DataLoadingStatus.LOADING));
    const { response, error } = yield call(
      webhookService.updateWebhook,
      data.payload,
    );
    if (response) {
      yield put(setUpdateWebhookLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setUpdateWebhookError(error?.message || error));
      yield put(setUpdateWebhookLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setUpdateWebhookError(error?.message || error));
    yield put(setUpdateWebhookLoading(DataLoadingStatus.ERROR));
  }
}

export function* createWebhookSaga(data: {
  type: typeof createWebhooks.type;
  payload: CreateWebhookRequest;
}) {
  try {
    yield put(setCreateWebhookLoading(DataLoadingStatus.LOADING));
    const { response, error } = yield call(
      webhookService.createWebhook,
      data.payload,
    );
    if (response) {
      yield put(setCreateWebhookLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setCreateWebhookError(error?.message || error));
      yield put(setCreateWebhookLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setCreateWebhookError(error?.message || error));
    yield put(setCreateWebhookLoading(DataLoadingStatus.ERROR));
  }
}

export function* deleteWebhookSaga(data: {
  type: typeof deleteWebhooks.type;
  payload: DeleteWebhookRequest;
}) {
  try {
    yield put(setDeleteWebhookLoading(DataLoadingStatus.LOADING));
    const { response, error } = yield call(
      webhookService.deleteWebhook,
      data.payload,
    );
    if (response) {
      yield put(setDeleteWebhookLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setDeleteWebhookError(error?.message || error));
      yield put(setDeleteWebhookLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setDeleteWebhookError(error?.message || error));
    yield put(setDeleteWebhookLoading(DataLoadingStatus.ERROR));
  }
}

export function* workflowSaga() {
  yield all([
    takeLatest(fetchWebhooks.type, listWebhooksSaga),
    takeLatest(updateWebhooks.type, updateWebhookSaga),
    takeLatest(createWebhooks.type, createWebhookSaga),
    takeLatest(deleteWebhooks.type, deleteWebhookSaga),
  ]);
}

export default workflowSaga;
