import produce from 'immer';
import { Reducer } from 'redux';
import { UserActionType } from '../actions/actions.constants';
import { OrgInfo, User } from 'protos/pb/v1alpha1/user';
import { storageService } from '../../services/StorageService';
import * as Sentry from '@sentry/react';
import { UserPermissions, createUserPermissions } from '../../utils/RbacUtils';
import { DataLoadingStatus } from '../../utils/constants';
export interface UserState {
  users: User[];
  loadingUsersStatus: DataLoadingStatus;
  nextPageToken?: string;
  totalSize?: number;
  listUsersError?: Error;
  loggedInUserLoadingStatus: DataLoadingStatus;
  updatingPassword: boolean;
  loggedInUser?: User;
  addUserError?: Error;
  addedUser?: User;
  updateUserError?: Error;
  updatedUser?: User;
  updateUserStatus: DataLoadingStatus;
  createUserStatus: DataLoadingStatus;
  selectedOrgInfo?: OrgInfo;
  selectedOrgInfoCompleted: boolean;
  updateGoogleTokenLoadingStatus: DataLoadingStatus;
  updateGoogleTokenError?: Error;
  updateMicrosoftTokenLoadingStatus: DataLoadingStatus;
  updateMicrosoftTokenError?: Error;
  userPermissions: UserPermissions;
}

const initialState: UserState = {
  users: [],
  loadingUsersStatus: DataLoadingStatus.INITIAL,
  loggedInUserLoadingStatus: DataLoadingStatus.INITIAL,
  updatingPassword: false,
  updateUserStatus: DataLoadingStatus.INITIAL,
  createUserStatus: DataLoadingStatus.INITIAL,
  updateGoogleTokenLoadingStatus: DataLoadingStatus.INITIAL,
  updateMicrosoftTokenLoadingStatus: DataLoadingStatus.INITIAL,
  userPermissions: createUserPermissions([]),
  selectedOrgInfoCompleted: false,
};

export const userReducer: Reducer<UserState> = (
  state: UserState = initialState,
  action,
) =>
  produce(state, (draft: UserState) => {
    switch (action.type) {
      case UserActionType.FETCH_LOGGED_IN_USER:
        draft.loggedInUserLoadingStatus = DataLoadingStatus.LOADING;
        break;
      case UserActionType.FETCH_LOGGED_IN_USER_COMPLETED: {
        draft.loggedInUserLoadingStatus = DataLoadingStatus.LOADED;
        draft.loggedInUser = action.payload.user;
        if (!action.payload.alreadyLoggedIn) {
          // Capture user id in sentry to get more information on which user is facing issues
          Sentry.setUser({
            id: draft.loggedInUser?.id,
          });
        }
        break;
      }
      case UserActionType.FETCH_LOGGED_IN_USER_ERROR:
        draft.loggedInUserLoadingStatus = DataLoadingStatus.ERROR;
        break;
      case UserActionType.UPDATE_USER:
        draft.updateUserStatus = DataLoadingStatus.LOADING;
        break;
      case UserActionType.UPDATE_USER_COMPLETED: {
        const user: User = action.payload;
        const index: number = draft.users.findIndex(
          (u) => u.email === user.email,
        );
        draft.updatedUser = user;
        if (index >= 0) {
          // IF SELECTED ORGANIZATION IS PRESENT IN USERORGLIST, THEN ORG IS UPDATED
          // OTHERWISE USER HAS BEEN REMOVED FROM THE ORGANIZATION
          const orgUpdated = user.orgInfos?.find((o) => {
            return o.orgResourceName === draft.selectedOrgInfo?.orgResourceName;
          });
          if (orgUpdated) {
            // UPDATE USER
            draft.users.splice(index, 1, user);
          } else {
            // REMOVE USER IF USER IS DELETED FROM ORG
            draft.users.splice(index, 1);
            if (draft.totalSize) draft.totalSize--;
            // WHEN DETAILS ARE UPDATED OF LOGGEDINUSER
            if (draft.loggedInUser?.email === user.email) {
              const orgInfos = user.orgInfos?.filter((o) => {
                return (
                  o.orgResourceName !== draft.selectedOrgInfo?.orgResourceName
                );
              });
              if (orgInfos?.length) {
                draft.selectedOrgInfo = orgInfos[0];
                storageService.setStoredOrgResourceName(
                  orgInfos?.[0]?.orgResourceName as string,
                );
              } else {
                storageService.deleteStoredValues();
                draft.loggedInUser = undefined;
              }
            }
          }
        }
        if (draft.loggedInUser?.email === user.email) {
          draft.loggedInUser = action.payload;
        }
        draft.updateUserStatus = DataLoadingStatus.LOADED;
        break;
      }
      case UserActionType.UPDATE_USER_ERROR:
        draft.updateUserError = action.payload;
        draft.updateUserStatus = DataLoadingStatus.ERROR;
        break;
      case UserActionType.UPDATE_PASSWORD:
        draft.updatingPassword = true;
        break;
      case UserActionType.UPDATE_PASSWORD_COMPLETED:
        draft.updatingPassword = false;
        break;
      case UserActionType.UPDATE_PASSWORD_ERROR:
        draft.updatingPassword = false;
        break;
      case UserActionType.LIST_USERS:
        draft.loadingUsersStatus = DataLoadingStatus.LOADING;
        break;
      case UserActionType.LIST_USERS_COMPLETED: {
        if (action.refresh) {
          draft.users = action.payload;
        } else {
          draft.users.push(...action.payload);
        }
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.loadingUsersStatus = DataLoadingStatus.LOADED;
        break;
      }
      case UserActionType.LIST_USERS_ERROR: {
        draft.loadingUsersStatus = DataLoadingStatus.ERROR;
        draft.listUsersError = action.payload;
        break;
      }
      case UserActionType.ADD_USER_TO_ORGANIZATION:
        draft.createUserStatus = DataLoadingStatus.LOADING;
        break;
      case UserActionType.ADD_USER_TO_ORGANIZATION_COMPLETED: {
        draft.addedUser = action.payload;
        draft.users.push(action.payload);
        draft.totalSize = (draft.totalSize ?? 0) + 1;
        draft.createUserStatus = DataLoadingStatus.LOADED;
        break;
      }
      case UserActionType.ADD_USER_TO_ORGANIZATION_ERROR: {
        draft.addUserError = action.payload;
        draft.createUserStatus = DataLoadingStatus.ERROR;
        break;
      }
      case UserActionType.SET_SELECTED_ORG_INFO_COMPLETED: {
        draft.selectedOrgInfoCompleted = true;
        break;
      }
      case UserActionType.SET_SELECTED_ORG_INFO: {
        draft.selectedOrgInfo = action.payload;
        draft.selectedOrgInfoCompleted = false;
        storageService.setStoredOrgResourceName(action.payload.orgResourceName);
        break;
      }
      case UserActionType.SET_ADDED_USER: {
        draft.addedUser = action.payload;
        break;
      }
      case UserActionType.SET_UPDATED_USER: {
        draft.updatedUser = action.payload;
        break;
      }
      case UserActionType.UPDATE_GOOGLE_TOKEN: {
        draft.updateGoogleTokenLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case UserActionType.UPDATE_GOOGLE_TOKEN_COMPLETED: {
        draft.updateGoogleTokenLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case UserActionType.UPDATE_GOOGLE_TOKEN_ERROR: {
        draft.updateGoogleTokenLoadingStatus = DataLoadingStatus.ERROR;
        draft.updateGoogleTokenError = action.payload;
        break;
      }
      case UserActionType.CLEAR_GOOGLE_TOKEN_UPDATE_STATUS: {
        draft.updateGoogleTokenLoadingStatus = DataLoadingStatus.INITIAL;
        break;
      }
      case UserActionType.UPDATE_MICROSOFT_TOKEN: {
        draft.updateMicrosoftTokenLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case UserActionType.UPDATE_MICROSOFT_TOKEN_COMPLETED: {
        draft.updateMicrosoftTokenLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case UserActionType.UPDATE_MICROSOFT_TOKEN_ERROR: {
        draft.updateMicrosoftTokenLoadingStatus = DataLoadingStatus.ERROR;
        draft.updateMicrosoftTokenError = action.payload;
        break;
      }
      case UserActionType.CLEAR_MICROSOFT_TOKEN_UPDATE_STATUS: {
        draft.updateMicrosoftTokenLoadingStatus = DataLoadingStatus.INITIAL;
        break;
      }

      case UserActionType.GET_USER_PERMISSIONS_COMPLETED: {
        draft.userPermissions = createUserPermissions(action.payload);
        break;
      }
      case UserActionType.GET_USER_PERMISSIONS_ERROR: {
        draft.userPermissions = createUserPermissions([]);
        break;
      }
      default:
        break;
    }
  });
