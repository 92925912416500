import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GetFiltersActionType } from '../actions/actions.constants';
import {
  getOrbotWorkflowFiltersCompletedAction,
  getOrbotWorkflowFiltersErrorAction,
  getUserFiltersCompletedAction,
  getUserFiltersErrorAction,
  getWorkflowFiltersCompletedAction,
  getWorkflowFiltersErrorAction,
} from '../actions/filter_options.action';
import { orbotService } from '../../services/OrbotService';
import { userService } from '../../services/UserService';
import { workflowService } from '../../services/WorkflowService';

export function* getUserFiltersSaga(data: {
  type: GetFiltersActionType;
  payload: string;
}): any {
  try {
    const { response, error } = yield call(
      userService.listAllUsers,
      data.payload,
    );

    if (response) {
      yield put(getUserFiltersCompletedAction(response));
    } else {
      yield put(getUserFiltersCompletedAction([]));
      yield put(getUserFiltersErrorAction(error));
    }
  } catch (error) {
    yield put(getUserFiltersErrorAction(error as Error));
  }
}

export function* getWorkflowFiltersSaga(data: {
  type: GetFiltersActionType;
  payload: {
    orgResourceName: string;
    isUnifiedWorkflowsViewEnabled?: boolean;

    // If this is true, then we will always use the unified workflows view irrespective of the feature flag
    alwaysUseUnifiedWorkflows?: boolean;
  };
}): any {
  try {
    const {
      orgResourceName,
      isUnifiedWorkflowsViewEnabled,
      alwaysUseUnifiedWorkflows,
    } = data.payload;

    let apiResponse;
    if (isUnifiedWorkflowsViewEnabled || alwaysUseUnifiedWorkflows) {
      apiResponse = yield call(orbotService.listAllWorkflows, orgResourceName);
    } else {
      apiResponse = yield call(
        workflowService.listAllWorkflows,
        orgResourceName,
      );
    }
    const response = apiResponse.response;
    const error = apiResponse.error;

    if (response) {
      yield put(getWorkflowFiltersCompletedAction(response));
    } else {
      yield put(getWorkflowFiltersCompletedAction([]));
      yield put(getWorkflowFiltersErrorAction(error));
    }
  } catch (error) {
    yield put(getWorkflowFiltersErrorAction(error as Error));
  }
}

export function* getOrbotWorkflowFiltersSaga(data: {
  type: GetFiltersActionType;
  payload: string;
}): any {
  try {
    const { response, error } = yield call(
      orbotService.listAllWorkflows,
      data.payload,
    );
    if (response) {
      yield put(getOrbotWorkflowFiltersCompletedAction(response));
    } else {
      yield put(getOrbotWorkflowFiltersCompletedAction([]));
      yield put(getOrbotWorkflowFiltersErrorAction(error));
    }
  } catch (error) {
    yield put(getOrbotWorkflowFiltersErrorAction(error as Error));
  }
}

function* filterOptionsSaga() {
  yield all([
    takeLatest(GetFiltersActionType.GET_USER_FILTERS, getUserFiltersSaga),
    takeLatest(
      GetFiltersActionType.GET_WORKFLOW_FILTERS,
      getWorkflowFiltersSaga,
    ),
    takeLatest(
      GetFiltersActionType.GET_ORBOT_WORKFLOW_FILTERS,
      getOrbotWorkflowFiltersSaga,
    ),
  ]);
}

export default filterOptionsSaga;
