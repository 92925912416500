import React from 'react';
import {
  Box,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import {
  Workflow,
  WorkflowActionsForReview,
} from 'protos/pb/v1alpha1/orbot_workflow';

import {
  OrbyBadge,
  OrbyBodyLabel,
  OrbyColorPalette,
  OrbyTypography,
} from 'orby-ui/src';
import UserCard from '../../../../components/UserCard';
import { formatDateTime, getDateRange } from '../../../../utils/helpers';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  CREATOR_IDS,
  DISPLAY_NAME_PREFIX,
  DateOptionValues,
  DateRange,
  ELLIPSIS_STYLE,
  END_DATE,
  NAME_PREFIX,
  PAGE_NO,
  PERIOD,
  SELECTED_TAB,
  START_DATE,
  WORKFLOW_MODES,
} from '../../../../utils/constants';
import { ListWorkflowsRequest } from 'protos/pb/v1alpha1/orbot_service';
import { getWorkflowIdsJoinedByDashV2 } from '../../../Utils/taskV2Utils';
import RunSchedule from '../../components/RunSchedule';
import { DEFAULT_FIRST_PAGE } from 'orby-ui/src/components/table/table-utils';
import ThreeDotActionMenu from '../../../../components/ThreeDotActionMenu';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: OrbyColorPalette['white-0'],
    color: OrbyColorPalette['black-0'],
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 'normal',
    padding: '12px',
    width: '281px',
    maxHeight: '428px',
  },
  '& .MuiTooltip-arrow': {
    color: OrbyColorPalette['white-0'],
  },
  '& .MuiTooltip-arrow::before': {
    boxShadow: '1px 1px 1px 0px #d4d2d2',
  },
}));

export const buildRequest = ({
  navigate,
  displayNamePrefix,
  orgResourceName,
  selectedTime,
  customRange,
  selectedUsers,
  selectedModes,
  pageNumber,
}: {
  navigate: NavigateFunction;
  displayNamePrefix: string;
  orgResourceName: string;
  selectedTime: string;
  customRange: DateRange;
  selectedUsers: Array<string>;
  selectedModes: Array<string>;
  pageNumber: number;
}) => {
  const req: ListWorkflowsRequest = ListWorkflowsRequest.create({
    filter: getFilters(
      navigate,
      displayNamePrefix,
      selectedTime,
      customRange,
      selectedUsers,
      selectedModes,
      pageNumber,
    ),
    orgId: orgResourceName.replace('organizations/', ''),
  });
  return req;
};

export const getFilters = (
  navigate: NavigateFunction,
  displayNamePrefix: string,
  selectedTime: string,
  customRange: DateRange,
  selectedUsers: Array<string>,
  selectedModes: Array<string>,
  pageNumber: number = DEFAULT_FIRST_PAGE,
) => {
  let filter = '';
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  if (selectedTime) {
    const dateRange = getDateRange(selectedTime, customRange);
    if (dateRange) {
      filter += `last_update_time_lt=${dateRange.endDate},last_update_time_gt=${dateRange.startDate}`;
      searchParams.append(PERIOD, selectedTime);
    }

    if (selectedTime === DateOptionValues.CUSTOM_RANGE) {
      searchParams.append(
        START_DATE,
        customRange.startDate.format('MM/DD/YYYY'),
      );
      searchParams.append(END_DATE, customRange.endDate.format('MM/DD/YYYY'));
    }
  }

  // SEARCH FILTER
  if (displayNamePrefix) {
    filter += `,${NAME_PREFIX}=${displayNamePrefix}`;
    searchParams.append(DISPLAY_NAME_PREFIX, displayNamePrefix);
  }

  // USERS FILTER
  if (selectedUsers.length) {
    filter += `,${CREATOR_IDS}=${getWorkflowIdsJoinedByDashV2(selectedUsers.map((user) => user.replace('users/', '')))}`;
    searchParams.append(
      CREATOR_IDS,
      getWorkflowIdsJoinedByDashV2(selectedUsers) || '',
    );
  }

  // MODES FILTER
  if (selectedModes.length) {
    filter += `,mode=${selectedModes.join('-')}`;
    searchParams.append(WORKFLOW_MODES, selectedModes.join('-'));
  }

  // GET THE TAB
  const selectedTab = existingParams.get(SELECTED_TAB) ?? 0;
  searchParams.append(PAGE_NO, pageNumber.toString());
  searchParams.append(SELECTED_TAB, selectedTab.toString());

  navigate(
    {
      search: `?${searchParams.toString()}`,
    },
    {
      replace: true, // Needed to prevent the back button from navigating to same page multiple times
    },
  );
  // Remove the first ','
  if (filter.length > 0 && filter[0] === ',') {
    filter = filter.substring(1);
  }
  return filter;
};

export const getWorkflowNameCell = (
  workflow: Workflow,
  displayNamePrefix: string,
): JSX.Element => {
  const name = workflow.displayName || '--';
  const startIndex = name
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      title={name}
      key={name}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {name.substring(startIndex, endIndex)}
          </span>
          {name.substring(endIndex)}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getWorkflowRunScheduleCell = (workflow: Workflow): JSX.Element => {
  return workflow?.schedules?.length ? (
    <RunSchedule
      schedule={workflow?.schedules?.[0]}
      tooltipChild={
        (workflow?.schedules?.length || 0) > 1 ? (
          <LightTooltip
            title={
              <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
                {workflow?.schedules
                  ?.slice(1)
                  .map((schedule) => (
                    <RunSchedule key={schedule.id} schedule={schedule} />
                  ))}
              </Box>
            }
            arrow
            placement='top'
          >
            <Box
              sx={{
                cursor: 'pointer',
                borderRadius: '100%',
                padding: '6px',
                backgroundColor: OrbyColorPalette['blueGrey-50'],
              }}
            >
              <OrbyTypography
                color={OrbyColorPalette['blueGrey-700']}
                size='sm'
                weight='medium'
              >
                +{(workflow?.schedules?.length || 0) - 1}
              </OrbyTypography>
            </Box>
          </LightTooltip>
        ) : (
          <></>
        )
      }
    />
  ) : (
    <>--</>
  );
};

const getReviewMode = (reviewModes?: WorkflowActionsForReview[]) => {
  if (reviewModes?.includes(WorkflowActionsForReview.ALL_ACTIONS)) {
    return 'Active Review';
  } else if (
    reviewModes?.includes(WorkflowActionsForReview.LOW_CONFIDENCE_ACTIONS) ||
    reviewModes?.includes(WorkflowActionsForReview.FINAL_ACTIONS)
  ) {
    return 'Assisted Mode';
  } else if (
    reviewModes?.includes(WorkflowActionsForReview.REVIEW_MODE_UNSPECIFIED)
  ) {
    return 'AutoPilot Mode';
  } else {
    return 'Assisted Mode';
  }
};

export const getWorkflowModeCell = (workflow: Workflow): JSX.Element => {
  return (
    <OrbyBadge
      key={workflow.id}
      backgroundColor={OrbyColorPalette['purple-50']}
      textColor={OrbyColorPalette['purple-700']}
      badgeName={getReviewMode(workflow.actionsForReview)}
      size='small'
      textSx={ELLIPSIS_STYLE}
    />
  );
};

export const getWorkflowCreatorCell = (workflow: Workflow): JSX.Element => {
  return (
    <UserCard
      email={workflow?.creator?.username || ''}
      fullName={workflow?.creator?.fullName}
      imageUrl={workflow?.creator?.imageUrl}
    />
  );
};

export const getWorkflowTimeCell = (workflow: Workflow): JSX.Element => {
  if (workflow.lastUpdateTime || workflow.createTime) {
    const { date, time } = formatDateTime(
      workflow.lastUpdateTime ?? workflow.createTime,
    );
    return (
      <Box key={workflow.id}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={workflow.id}
        label={'--'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getWorkflowActionMenuCell = (
  workflow: Workflow,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    workflow: Workflow,
  ) => void,
): JSX.Element => {
  return (
    <ThreeDotActionMenu
      onClick={(
        e:
          | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
          | React.KeyboardEvent<HTMLDivElement>,
      ) => handleActionMenuClick(e, workflow)}
      iconSx={{ color: OrbyColorPalette['grey-400'] }}
      menuId={workflow.id}
    />
  );
};

export const ORBOT_WORKFLOW_ACTION_MENU_ITEM = {
  DELETE: 'Delete',
};

export const getWorkflowActionItem = (
  actionMenuAnchorEl: HTMLElement | null,
  open: boolean,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
  workflow?: Workflow | null,
) => {
  const navigate = useNavigate();
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {workflow && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={() => {
            navigate(`/workflow/${workflow.id}/definition`);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Edit
          </OrbyTypography>
        </MenuItem>
      )}
      {/* DELETE */}
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, ORBOT_WORKFLOW_ACTION_MENU_ITEM.DELETE);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['error-700']}
        >
          Delete
        </OrbyTypography>
      </MenuItem>
    </Menu>
  );
};

/**
 * Handle Workflow Page Change
 */
export const handleWorkflowPageChange = ({
  pageNumber,
  rowsPerPage,
  setPage,
  listWorkflows,
}: {
  pageNumber: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listWorkflows: (pageNumber: number, rowsPerPage: number) => void;
}) => {
  setPage(pageNumber);
  listWorkflows(pageNumber, rowsPerPage);
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleWorkflowsRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listWorkflows,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listWorkflows: (pageNumber: number, rowsPerPage: number) => void;
}) => {
  setPage(DEFAULT_FIRST_PAGE);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listWorkflows(DEFAULT_FIRST_PAGE, rowsNumber);
};

export const getWorkflowNameColumnWidth = (
  tableWidth: number,
  minWidth?: number,
) => {
  // Table Width - Apps Cell Width - Workflow Mode Cell Width - Creator Cell Width - Workflow Mode Cell Width - Workflow Action Cell Width
  const width =
    (tableWidth - 174 - 142 - 217 - getWorkflowTimeWidth(tableWidth) - 69) / 2;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getWorkflowTimeWidth = (tableWidth: number) => {
  return tableWidth >= 1272 ? 182 : 139;
};
