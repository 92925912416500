import {
  CreateWebhookRequest,
  DeleteWebhookRequest,
  ListWebhooksRequest,
  UpdateWebhookRequest,
  WebhooksClientImpl,
} from 'protos/pb/v1alpha2/webhook_service';
import { storageService } from './StorageService';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';

class WebhookService {
  private static instance: WebhookService;

  private static client = new WebhooksClientImpl(rpcWithErrorHandling);

  public static getInstance(): WebhookService {
    if (!this.instance) {
      this.instance = new WebhookService();
    }
    return this.instance;
  }

  async getWebhookList(req: ListWebhooksRequest) {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await WebhookService.client.ListWebhooks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateWebhook(req: UpdateWebhookRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WebhookService.client.UpdateWebhook(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createWebhook(req: CreateWebhookRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WebhookService.client.CreateWebhook(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteWebhook(req: DeleteWebhookRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WebhookService.client.DeleteWebhook(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const webhookService = WebhookService.getInstance();
