// @ts-nocheck
import { lazy } from 'react';

export const LazyDashboard = lazy(() => import('../Dashboard/Dashboard'));
export const LazyTaskReviewDetails = lazy(
  () => import('../AutomationReviewPage/TaskReviewDetails'),
);
export const LazyDebuggerReview = lazy(
  () => import('../DebuggerReview/DebuggerReview'),
);
export const LazyIntegrationTest = lazy(
  () => import('../IntegrationTest/IntegrationTest'),
);
export const LazyScheduledTasks = lazy(
  () => import('../ScheduledTasks/ScheduledTasks'),
);
export const LazyFeatureFlags = lazy(
  () => import('../FeatureFlags/FeatureFlags'),
);
export const LazyFeatureFlagCreationPage = lazy(
  () => import('../FeatureFlags/FeatureFlagCreationPage'),
);
export const LazyFeatureFlagUpdatePage = lazy(
  () => import('../FeatureFlags/FeatureFlagUpdatePage'),
);
export const LazyTasks = lazy(() => import('../Tasks/Tasks'));
export const LazyTasksExport = lazy(
  () => import('../Tasks/component/TasksExport'),
);
export const LazyExecutions = lazy(() => import('../Executions/Executions'));
export const LazyExecutionHistory = lazy(
  () => import('../ExecutionHistory/ExecutionHistory'),
);
export const LazySettings = lazy(() => import('../Settings/Settings'));

export const LazyProcessDiscovery = lazy(
  () => import('../ProcessDiscovery/ProcessDiscovery'),
);
export const LazyProcessDiscoveryDetails = lazy(
  () => import('../ProcessDiscovery/ProcessDiscoveryDetails'),
);
export const LazyCreateProcessDiscovery = lazy(
  () => import('../ProcessDiscovery/ProcessDiscoveryCreation'),
);

export const LazyConfigureSSO = lazy(
  () => import('../Settings/ConfigureSSO/ConfigureSSO'),
);
export const LazyCompanyNonWorkingDays = lazy(
  () => import('../Settings/CompanyNonWorkingDays'),
);
export const LazyWebhooks = lazy(() => import('../Settings/Webhooks'));
export const LazyMachines = lazy(() => import('../Settings/Machines'));
export const LazyApiKeysManagement = lazy(
  () => import('../Settings/ApiKeysManagement'),
);
export const LazyUiAutomationsDetail = lazy(
  () =>
    import(
      '../ExecutionHistory/tabs/ui-automations/details/UiAutomationsDetail'
    ),
);
export const LazyWorkflowDetails = lazy(
  () => import('../MyWorkflows/details/WorkflowDetails'),
);
export const LazyTemplate = lazy(
  () => import('../MyWorkflows/details/Template'),
);
export const LazyCreateWorkflowTemplate = lazy(
  () => import('../MyWorkflows/CreateWorkflow/CreateWorkflowTemplate'),
);
export const LazyCreateConnector = lazy(
  () => import('../MyWorkflows/CreateConnector/CreateConnector'),
);
export const LazyUpdateConnector = lazy(
  () => import('../MyWorkflows/CreateConnector/UpdateConnector'),
);
export const LazyUpdateWorkflow = lazy(
  () => import('../MyWorkflows/CreateWorkflow/UpdateWorkflow'),
);
export const LazyCreateWorkflow = lazy(
  () => import('../MyWorkflows/CreateWorkflow/CreateWorkflow'),
);
export const LazyTeamsPage = lazy(() => import('../Teams/TeamsPage'));
export const LazyFieldsPage = lazy(() => import('../Fields/FieldsPage'));
export const LazyMyWorkflows = lazy(() => import('../MyWorkflows/index'));
export const LazyTrashCan = lazy(() => import('../TrashCan/TrashCanPage'));
