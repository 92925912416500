import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { organizationsService } from '../../services/OrganizationsService';
import {
  listApiKeys,
  listApiKeysSuccessAction,
  listApiKeysFailureAction,
  createApiKey,
  createApiKeySuccessAction,
  createApiKeyFailureAction,
} from '../slices/api_keys.slice';
import {
  CreateApiKeyRequest,
  ListApiKeysRequest,
} from 'protos/pb/v1alpha1/organization_service';

function* handleListApiKeys(action: PayloadAction<ListApiKeysRequest>) {
  try {
    const { response, error } = yield call(
      organizationsService.listApiKeys,
      action.payload,
    );
    if (response) {
      yield put(
        listApiKeysSuccessAction({
          apiKeys: response.apiKeys,
          totalSize: response.totalSize,
        }),
      );
    } else {
      const errorMessage = error?.message || 'Failed to fetch API keys';
      yield put(listApiKeysFailureAction(errorMessage));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Failed to fetch API keys';
    yield put(listApiKeysFailureAction(errorMessage));
  }
}

function* handleCreateApiKey(
  action: PayloadAction<
    CreateApiKeyRequest & { onSuccess?: (apiKey: string) => void }
  >,
) {
  try {
    const { response, error } = yield call(
      organizationsService.createApiKey,
      action.payload,
    );
    if (response && response.apiKey) {
      yield put(createApiKeySuccessAction(response.apiKey.encryptedKey || ''));
      if (action.payload.onSuccess) {
        yield call(
          action.payload.onSuccess,
          response.apiKey.encryptedKey || '',
        );
      }
    } else {
      const errorMessage = error?.message || 'Failed to create API key';
      yield put(createApiKeyFailureAction(errorMessage));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Failed to create API key';
    yield put(createApiKeyFailureAction(errorMessage));
  }
}

export function* apiKeysSaga() {
  yield takeLatest(listApiKeys.type, handleListApiKeys);
  yield takeLatest(createApiKey.type, handleCreateApiKey);
}
