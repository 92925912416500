import moment, { Moment } from 'moment';
import {
  CURRENCY_CODES,
  CURRENCY_SYMBOLS_SET,
  CURRENCY_SYMBOLS_SET_MAP,
  DATE_FORMATS,
} from './normalization.constants';
import { normalizeWhitespace } from '../helpers';
import { DocumentEntityNormalizedValue } from 'protos/google/cloud/documentai/v1/document';
import { EntityInfo } from '../../redux/reducers/review_task.reducer';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';

// Maximum value of integers
export const INT32_MAX = 2147483647;
export const NEGATIVE_INT32_MAX = -2147483648;
export const INT64_MAX = BigInt('9223372036854775807');
export const NEGATIVE_INT64_MAX = BigInt('-9223372036854775808');

// ################## Regex Patterns for Numerical Values #########################
const RE_NUMBER = /(?:\d+(?:[,.]?\d{1})*(?:[,.]\d+)?)/;

// // ####################### Regex Patterns for Currencies ###############################

const RE_CODES = new RegExp(`(${CURRENCY_CODES.join('|')})`);
const RE_SYMBOLS = new RegExp(`(${CURRENCY_SYMBOLS_SET.join('|')})`);
const RE_CURRENCY = new RegExp(
  `(${CURRENCY_CODES.concat(CURRENCY_SYMBOLS_SET).join('|')})`,
);
const RE_SIGNED_NUMBER = new RegExp(`[+-]?${RE_NUMBER.source}`);

const RE_CURR_NUMBER = new RegExp(
  `^${RE_CURRENCY.source}[\\s]*${RE_SIGNED_NUMBER.source}$`,
  'i',
); // `i` flag is used for case-insensitive comparison
const RE_NUMBER_CURR = new RegExp(
  `^${RE_SIGNED_NUMBER.source}[\\s]*${RE_CURRENCY.source}$`,
  'i',
);

const RE_SYM_NUMBER_CODE = new RegExp(
  `^${RE_SYMBOLS.source}[\\s]*${RE_SIGNED_NUMBER.source}[\\s]*${RE_CODES.source}$`,
  'i',
);
const RE_CODE_NUMBER_SYM = new RegExp(
  `^${RE_CODES.source}[\\s]*${RE_SIGNED_NUMBER.source}[\\s]*${RE_SYMBOLS.source}$`,
  'i',
);

const RE_EXACT_NUMBER = new RegExp(`^${RE_SIGNED_NUMBER.source}$`);
const RE_EXACT_CODE = new RegExp(`^${RE_CODES.source}$`, 'i');
const RE_EXACT_SYMBOL = new RegExp(`^${RE_SYMBOLS.source}$`);

// This function
// 1. process currency values from a given text and returns a normalized value.
// 2. process large numbers (number only values) which cannot be handled by float, integer https://orby-ai.atlassian.net/browse/OA-2607
// 3. process currency only values https://orby-ai.atlassian.net/browse/OA-2636
export const processCurrency = (mentionText: string) => {
  // Regular expressions for different currency formats
  // Eg. mentionText = "$ 610.81234"
  if (
    !(
      // matches `{validCode | validSymbol} {number}`
      (
        mentionText.match(RE_CURR_NUMBER) ||
        // matches `{number} {validCode | validSymbol}`
        mentionText.match(RE_NUMBER_CURR) ||
        // matches `{validSymbol} {number} {validCode}`
        mentionText.match(RE_SYM_NUMBER_CODE) ||
        // matches `{validCode} {number} {validSymbol}`
        mentionText.match(RE_CODE_NUMBER_SYM) ||
        // match only numbers
        mentionText.match(RE_EXACT_NUMBER) ||
        // match only codes
        mentionText.match(RE_EXACT_CODE) ||
        // match only symbols
        mentionText.match(RE_EXACT_SYMBOL)
      )
    )
  ) {
    // If the mentionText does not match any currency format, return null
    return null;
  }

  let currency = '';

  // Check for currency codes in the text
  for (const code of CURRENCY_CODES) {
    if (mentionText.toUpperCase().includes(code)) {
      currency = code;
      break;
    }
    // check for currency symbols
    const symbol =
      CURRENCY_SYMBOLS_SET_MAP[code as keyof typeof CURRENCY_SYMBOLS_SET_MAP];
    if (symbol && mentionText.includes(symbol)) {
      currency = code;
      break;
    }
  }

  // Extract the numeric part of the text
  // Eg. numberStr = "610.81234" & CURRENCY = "USD"
  const numberMatch = mentionText.match(RE_SIGNED_NUMBER);
  let numberStr = numberMatch === null ? '0' : numberMatch.join('');

  // Remove commas and parse the string to a number
  // Eg. number = 610.81234
  const number = parseFloat(numberStr.replace(/,/g, ''));

  // Format the number as a string with a maximum of 20 fraction digits
  numberStr = number
    .toLocaleString('en-US', { maximumFractionDigits: 20 })
    .toString()
    .replace(/,/g, '');

  // Split the number into units and nanos
  // Eg. numSplit = ["610", "81234"]
  const numSplit = numberStr.split('.');

  // Eg. moneyUnits = 610
  const moneyUnits = parseInt(numSplit[0]);
  // Check if moneyUnits lies in the permissible range
  if (!isIntegerInRange(moneyUnits)) {
    return null;
  }
  const isNegative = number < 0;
  let moneyNanos = 0;
  if (numSplit.length > 1) {
    let fractional = numSplit[1];
    // Ensure fractional part is 9 digits or pad with zeros
    fractional =
      fractional.length > 9
        ? fractional.slice(0, 9)
        : fractional + '0'.repeat(9 - fractional.length);
    // Eg. moneyNanos = 812340000
    // Special Case : -0.212 in this case we cannot rely on units to store sign information( 0 cannot carry sign)
    // instead we rely on nanos to store that sign information
    moneyNanos = isNegative ? -1 * parseInt(fractional) : parseInt(fractional);
  }

  // Create a moneyValue object with currency code, units, and nanos
  // Eg. moneyValue = {currencyCode: 'USD', units: 610, nanos: 812340000}
  const moneyValue = {
    currencyCode: currency,
    units: moneyUnits,
    nanos: moneyNanos,
  };

  // Create a normalizedValue object with moneyValue and formatted text
  // Eg. normalizedValue = {moneyValue: {currencyCode: 'USD', units: 610, nanos: 812340000}, text: "USD 610.81234"}
  const normalizedValue = {
    moneyValue,
    text: currency + ' ' + numberStr,
  };
  // Return the normalizedValue
  return normalizedValue;
};

// This function processes numerical values from a given text and returns a normalized value.
export const processNumber = (
  mentionText: string,
): { integerValue?: number; floatValue?: number } | null => {
  if (!mentionText) {
    return null;
  }

  // Eg. mentionText = "1234567.123"
  // Remove specific characters from the mentionText
  const mentionTextStripped = mentionText.replace(/[+\-%/()]/g, '');

  // Eg. mentionTextStripped = "1234567.123"
  // If the stripped text does not match the RE_NUMBER regex, return null
  const numberMatch = mentionTextStripped.match(RE_NUMBER);
  if (!numberMatch) {
    return null;
  }

  // Extract the numeric part of the stripped text
  const number = numberMatch.join('');
  // If the extracted number is empty, return null
  if (number === '') {
    return null;
  }

  try {
    // Remove commas and parse the string to a number
    const parsedNumber = parseFloat(number.replace(/,/g, ''));
    const numStrWithNoCommas = parsedNumber
      .toLocaleString('en-US', { maximumFractionDigits: 20 })
      .toString()
      .replace(/,/g, '');
    // Eg. numStrWithNoCommas = "1234567.123"
    // Check if the resulting value is NaN
    if (isNaN(Number(numStrWithNoCommas))) {
      return null;
    }

    // Determine the sign of the number (positive or negative)
    const sign = mentionText.includes('-') ? -1 : 1;

    if (!numStrWithNoCommas.includes('.')) {
      // If the number is an integer
      const num = sign * parseInt(numStrWithNoCommas);
      // Check if the integer value is between -2,147,483,648 and 2,147,483,647
      if (num < NEGATIVE_INT32_MAX || num > INT32_MAX) {
        return null;
      }

      // Create a normalizedValue object with the integer value
      const normalizedValue = {
        integerValue: num,
        floatValue: num, // since integers are also subset of floating numbers
      };

      // Eg. normalizedValue = {integerValue: 1234567}
      return normalizedValue;
    } else {
      // If the number is a floating-point number
      // Create a normalizedValue object with the float value
      const normalizedValue = {
        floatValue: sign * parseFloat(numStrWithNoCommas),
      };
      // Eg. normalizedValue = {floatValue: 1234567.123}
      return normalizedValue;
    }
  } catch {
    return null;
  }
};

const parseDate = (cleanDate: string) => {
  // Define an array of possible date formats
  let parsedDate: Moment | null = null;
  // Iterate through the formats and parse the date strictly
  for (const format of DATE_FORMATS) {
    parsedDate = moment(cleanDate, format, true);
    if (parsedDate.isValid()) {
      break; // Exit the loop if a valid date is found
    }
  }
  return parsedDate;
};

export const processDate = (mentionText: string) => {
  // Remove st, nd, rd, th from date string to make it parsable by moment
  let cleanDate = mentionText.replace(/(\d+)(st|nd|rd|th)/g, '$1');

  // Remove all commas and replace them with single space
  cleanDate = cleanDate.replace(/,/g, ' ');

  // Replace all uneven white space characters with single space
  cleanDate = normalizeWhitespace(cleanDate);
  const parsedDate = parseDate(cleanDate);
  if (!parsedDate || !parsedDate?.isValid()) {
    return null;
  }

  const dateValue = {
    month: parsedDate.month() + 1,
    day: parsedDate.date(),
    year: parsedDate.year(),
  };

  const normalizedValue = {
    dateValue: dateValue,
    text: parsedDate.format('YYYY-MM-DD'),
  };

  return normalizedValue;
};

export const processText = (inputString: string) => {
  return normalizeWhitespace(inputString);
};

export const moneyToText = (units: number, nanos: number) => {
  const integerValue = `${units ?? ''}`;
  const fractionalValue = nanos
    ? Math.abs(nanos).toString().padStart(9, '0')
    : '';
  if (fractionalValue) {
    // only add negative sign explicity when units is 0 and nanos is negative
    const shouldAddNegativeSign = units === 0 && nanos < 0;
    return `${
      shouldAddNegativeSign ? '-' : ''
    }${integerValue}.${fractionalValue}`;
  }
  return integerValue;
};

export const isValidNumber = (value: string) => {
  const RE_VALID_NUMBER = /^[-+]?\d+(\.?\d+)?$/;
  return RE_VALID_NUMBER.test(value);
};

export const isIntegerInRange = (int: number) => {
  return int >= NEGATIVE_INT64_MAX && int <= INT64_MAX;
};

export const isValidCurrencyNumber = (value: string) => {
  if (!isValidNumber(value)) return false;

  const integerPart = parseInt(value.split('.')[0]); // eg '6054.53' -> '6054'
  return isIntegerInRange(integerPart);
};

export const processCurrencyChange = (
  value: string,
  entityInfo: EntityInfo,
) => {
  const normalizedText = processText(
    `${value} ${entityInfo.normalizedInputValue}`,
  );
  const newEntityInfo = {
    ...entityInfo,
    normalizedValue: {
      moneyValue: {},
      text: normalizedText,
    },
  };
  const parsedMoney = processCurrency(normalizedText);
  if (parsedMoney) {
    newEntityInfo.normalizedValue.moneyValue = parsedMoney.moneyValue;
  }
  return newEntityInfo;
};

const processMoneyValueChange = (value: string, entityInfo: EntityInfo) => {
  const currencyCode =
    entityInfo?.normalizedValue?.moneyValue?.currencyCode ?? '';
  const normalizedText = processText(`${currencyCode} ${value}`);

  const newEntityInfo = {
    ...entityInfo,
    normalizedInputValue: value,
    normalizedValue: {
      moneyValue: {},
      text: normalizedText,
    },
  };
  const parsedMoney = processCurrency(normalizedText);
  if (isValidCurrencyNumber(value.trim()) && parsedMoney) {
    newEntityInfo.normalizedValue = parsedMoney;
  }
  return newEntityInfo;
};

const processNumberValueChange = (value: string, entityInfo: EntityInfo) => {
  const newEntityInfo = {
    ...entityInfo,
    normalizedInputValue: value,
    normalizedValue: {
      text: processText(value),
    } as DocumentEntityNormalizedValue,
  };
  const normalizedValue = processNumber(value ?? '');
  if (normalizedValue) {
    if ('floatValue' in normalizedValue) {
      const floatValue = normalizedValue.floatValue;
      newEntityInfo.normalizedValue.floatValue = floatValue;
    } else if ('integerValue' in normalizedValue) {
      const integerValue = normalizedValue.integerValue;
      newEntityInfo.normalizedValue.integerValue = integerValue;
    }
  }
  return newEntityInfo;
};

const processTextValueChange = (value: string, entityInfo: EntityInfo) => {
  const newEntityInfo = { ...entityInfo };
  const text = processText(value);
  newEntityInfo.normalizedValue = { text };
  newEntityInfo.normalizedInputValue = value;
  return newEntityInfo;
};

const processDateValueChange = (value: string, entityInfo: EntityInfo) => {
  const parsedDate = processDate(value);
  const newEntityInfo = {
    ...entityInfo,
    normalizedInputValue: value,
    normalizedValue: {
      text: processText(value),
      dateValue: {},
    },
  };
  if (parsedDate) {
    newEntityInfo.normalizedValue.dateValue = parsedDate.dateValue;
  }
  return newEntityInfo;
};

export const processFinalValueChange = (
  value: string,
  entityInfo: EntityInfo,
) => {
  switch (entityInfo?.normalizedEntityType) {
    case EntityDataType.ENTITY_TYPE_FLOAT:
    case EntityDataType.ENTITY_TYPE_INTEGER: {
      return processNumberValueChange(value, entityInfo);
    }
    case EntityDataType.ENTITY_TYPE_TEXT: {
      return processTextValueChange(value, entityInfo);
    }
    case EntityDataType.ENTITY_TYPE_DATE: {
      return processDateValueChange(value, entityInfo);
    }
    case EntityDataType.ENTITY_TYPE_MONEY: {
      return processMoneyValueChange(value, entityInfo);
    }
    default:
      return entityInfo;
  }
};
