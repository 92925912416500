import { Box, CircularProgress } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import machineRegistrationError from '../../static/machine-reg-failed.svg';
import machineRegistrationSuccess from '../../static/machine-reg-successful.svg';
import OrbyLoginLogo from '../../static/orby.svg';
import { sendMessageToExtension } from '../../utils/extension';

export default function MachineRegistration() {
  // get auth_code and agent_id from the query params
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('auth_code');
  const agentId = searchParams.get('agent_id');
  const connectionStatus = searchParams.get('connection_status');
  const machineName = searchParams.get('machine_name');

  const sendAuthenticationDetailsToExtension = async () => {
    await sendMessageToExtension({
      type: 'authenticateMachine',
      authCode,
      agentId,
    });
  };

  useEffect(() => {
    if (authCode && agentId) {
      sendAuthenticationDetailsToExtension();
    }
  }, [authCode, agentId]);

  if (!connectionStatus) {
    // Show a loading spinner till a connection status is received
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vh'}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
      }}
    >
      <img src={OrbyLoginLogo} alt='orby-logo' width={'232px'} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '50px',
          marginTop: '50px',
          alignItems: 'center',
        }}
      >
        {connectionStatus === 'success' ? (
          <>
            <OrbyTypography
              weight='semibold'
              color={OrbyColorPalette['grey-900']}
              size='display-lg'
            >
              {machineName} connection successful
            </OrbyTypography>
            <OrbyTypography
              weight='regular'
              color={OrbyColorPalette['grey-900']}
              size='md'
            >
              Please keep the browser open to ensure a successful connection
            </OrbyTypography>
            <img
              alt='Success'
              style={{ width: '240px', marginTop: '300px' }}
              src={machineRegistrationSuccess}
            />
          </>
        ) : connectionStatus === 'error' ? (
          <>
            <OrbyTypography
              weight='semibold'
              color={OrbyColorPalette['grey-900']}
              size='display-lg'
            >
              Connection failed
            </OrbyTypography>
            <img
              alt='Error'
              style={{ width: '240px', marginTop: '300px' }}
              src={machineRegistrationError}
            />
          </>
        ) : undefined}
      </Box>
    </Box>
  );
}
