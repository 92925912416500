export enum APP_ROUTES {
  MACHINE_REGISTRATION_REDIRECT = '/auth',

  DASHBOARD = '/dashboard',
  TASKS = '/tasks',
  TASKS_EXPORT = '/tasks/export/:notification_id',
  ALL_EXECUTIONS = '/all-executions',
  EXECUTIONS = '/executions',
  EXECUTION_DETAILS = '/executions/:workflow_task_id',
  WORKFLOW_TEMPLATE = '/workflow/template',
  TASK_REVIEW = '/organisations/:organisation_id/workflows/:workflow_id/tasks/:task_id/automation-review',
  TASK_REVIEW_LEGACY = '/workflows/:workflow_id/tasks/:task_id/automation-review',
  HITL_DETAILS = '/executions/:workflow_id/tasks/:task_id/hitl-details',
  DEV_TESTING = '/dev-testing',
  DEV_TESTING_DOCUMENT = '/dev-testing/document/:file_name',
  INTEGRATION_TEST = '/integration-test',
  WORKFLOW = '/workflow',
  WORKFLOW_DEFINITION = '/workflow/:workflow_id/definition',
  WORKFLOW_UPDATE = '/workflow/:workflow_id/update',
  WORKFLOW_CREATE = '/workflow/create',
  WORKFLOW_SETTINGS = '/workflow/:workflow_id/settings',
  WORKFLOW_ADMINS = '/workflow/:workflow_id/admins',
  WORKFLOW_SCHEDULE = '/workflow/:workflow_id/schedule',
  WORKFLOW_ADVANCED_EDITOR = '/workflow/:workflow_id/advanced-editor',
  DELETED_ITEMS = '/deleted-items',
  FIELDS = '/fields',
  TEMPLATE_DEFINITION = '/template/:template_id/definition',
  CONNECTOR_CREATE = '/workflow/connector/create',
  CONNECTOR_UPDATE = '/workflow/connector/:connector_id/update',
  SETTINGS = '/settings',
  CONFIGURE_SSO = '/settings/configure-sso/:organization_id',
  COMPANY_NON_WORKING_DAYS = '/settings/company-non-working-days',
  MACHINES = '/settings/machines',
  WEBHOOKS = '/settings/webhooks',
  API_KEYS_MANAGEMENT = '/settings/api-keys',
  SCHEDULED_TASKS = '/scheduled-tasks',
  TEAMS = '/teams',
  FEATURE_FLAGS = '/feature-flags',
  FEATURE_FLAGS_CREATE = '/feature-flags/create',
  FEATURE_FLAGS_UPDATE = '/feature-flags/:feature_flag_id/update',

  PROCESS_DISCOVERY = '/process-discovery',
  PROCESS_DISCOVERY_DIAGRAM = '/process-discovery/:process_id/diagram',
  PROCESS_DISCOVERY_DOCUMENTATION = '/process-discovery/:process_id/documentation',
  PROCESS_DISCOVERY_SETTINGS = '/process-discovery/:process_id/settings',
  CREATE_PROCESS_DISCOVERY = '/process-discovery/create',
}
