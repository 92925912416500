import { SxProps, Theme, Typography } from '@mui/material';
import React, { ElementType } from 'react';
import OrbyColorPalette from '../colors/ColorPalette';
import {
  FontWeight,
  TypographySize,
  getFontSize,
  getFontWeight,
  getLineHeight,
} from './typography-utils';
import '../../styles/global.css';

export interface OrbyTypographyProps {
  /**
   * The content of the component.
   */
  children: React.ReactNode;
  /**
   * Set the font-size on the component.
   * @default 'sm'
   */
  size?: TypographySize;
  /**
   * Set the font-weight on the component.
   * @default 'regular'
   */
  weight?: FontWeight;
  /**
   * The id of the component.
   */
  id?: string;
  /**
   * The aria-label attribute of the component.
   */
  ariaLabel?: string; // Optional prop for aria-label attribute
  /**
   * The color of the text.
   * @default 'grey-900'
   */
  color?: string;
  /**
   * The HTML element type of the component.
   * @default 'p'
   */
  componentType?: ElementType<any>;
  /**
   * The tabIndex attribute of the component.
   */
  tabIndex?: number; // Optional prop for tabIndex attribute
  /**
   * The onClick event handler of the component.
   */
  onClick?: (e?: any) => void; // Optional prop for onClick event handler
  /**
   * The custom styles for the component
   */
  sx?: SxProps<Theme>;
  /**
   * The title for the displayed text.
   */
  title?: string;
}

const OrbyTypography: React.FC<OrbyTypographyProps> = (props) => {
  const {
    title,
    children,
    size,
    weight,
    id,
    ariaLabel,
    color,
    componentType,
    tabIndex,
    onClick,
    sx,
  } = props;

  return (
    <Typography
      sx={{
        ...sx,
        fontFamily: 'var(--font-family-inter-variable)',
      }}
      title={title}
      id={id}
      fontWeight={getFontWeight(weight ?? 'regular')}
      fontSize={getFontSize(size ?? 'sm')}
      lineHeight={getLineHeight(size ?? 'sm')}
      color={color ?? OrbyColorPalette['grey-900']}
      onClick={onClick}
      component={componentType ?? 'p'}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
    >
      {children}
    </Typography>
  );
};

export default React.memo(OrbyTypography);
