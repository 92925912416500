import { createSlice, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  CreateMachineRequest,
  CreateMachineResponse,
  DeleteMachineRequest,
  DeregisterMachineRequest,
  GenerateAuthCodeRequest,
  GenerateAuthCodeResponse,
  ListMachinesRequest,
  ListMachinesResponse,
  RegisterMachineRequest,
  RegisterMachineResponse,
} from 'protos/pb/v1alpha1/machine_identity_service';
import { DataLoadingStatus } from '../../utils/constants';

export interface MachinesState {
  machinesListResponse: ListMachinesResponse | null;
  machinesListLoadingStatus: DataLoadingStatus;
  machinesListError: string | null;
  createMachineLoadingStatus: DataLoadingStatus;
  createMachineResponse: CreateMachineResponse | null;
  createMachineError: string | null;
  registerMachineLoadingStatus: DataLoadingStatus;
  registerMachineResponse: RegisterMachineResponse | null;
  registerMachineError: string | null;
  deregisterMachineLoadingStatus: DataLoadingStatus;
  deregisterMachineError: string | null;
  deleteMachineLoadingStatus: DataLoadingStatus;
  deleteMachineError: string | null;

  generateAuthCodeLoadingStatus: DataLoadingStatus;
  generateAuthCodeResponse: GenerateAuthCodeResponse | null;
  generateAuthCodeError: string | null;
}

const initialState: MachinesState = {
  machinesListResponse: null,
  machinesListLoadingStatus: DataLoadingStatus.INITIAL,
  machinesListError: null,
  createMachineLoadingStatus: DataLoadingStatus.INITIAL,
  createMachineResponse: null,
  createMachineError: null,
  registerMachineLoadingStatus: DataLoadingStatus.INITIAL,
  registerMachineResponse: null,
  registerMachineError: null,
  deregisterMachineLoadingStatus: DataLoadingStatus.INITIAL,
  deregisterMachineError: null,
  deleteMachineLoadingStatus: DataLoadingStatus.INITIAL,
  deleteMachineError: null,

  generateAuthCodeLoadingStatus: DataLoadingStatus.INITIAL,
  generateAuthCodeResponse: null,
  generateAuthCodeError: null,
};

/**
 * The actions are created here to be used in the machines.saga.ts file.
 */

export const createMachine = createAction<CreateMachineRequest>(
  'machines/createMachine',
);

export const fetchMachines = createAction<ListMachinesRequest>(
  'machines/fetchMachines',
);

export const registerMachine = createAction<RegisterMachineRequest>(
  'machines/registerMachine',
);

export const deregisterMachine = createAction<DeregisterMachineRequest>(
  'machines/deregisterMachine',
);

export const deleteMachine = createAction<DeleteMachineRequest>(
  'machines/deleteMachine',
);

export const generateAuthCode = createAction<GenerateAuthCodeRequest>(
  'machines/generateAuthCode',
);

export const machinesSlice = createSlice({
  name: 'machines',
  initialState,

  reducers: {
    setMachines: (state, action: PayloadAction<ListMachinesResponse>) => {
      state.machinesListResponse = action.payload;
    },
    setMachinesListLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.machinesListLoadingStatus = action.payload;
    },
    setMachinesListError: (state, action: PayloadAction<string | null>) => {
      state.machinesListError = action.payload;
    },
    setCreateMachineResponse: (
      state,
      action: PayloadAction<CreateMachineResponse>,
    ) => {
      state.createMachineResponse = action.payload;
    },
    setCreateMachineLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.createMachineLoadingStatus = action.payload;
    },
    setCreateMachineError: (state, action: PayloadAction<string | null>) => {
      state.createMachineError = action.payload;
    },
    setRegisterMachineResponse: (
      state,
      action: PayloadAction<RegisterMachineResponse>,
    ) => {
      state.registerMachineResponse = action.payload;
    },
    setRegisterMachineLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.registerMachineLoadingStatus = action.payload;
    },
    setRegisterMachineError: (state, action: PayloadAction<string | null>) => {
      state.registerMachineError = action.payload;
    },
    setDeregisterMachineLoadingStatus: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.deregisterMachineLoadingStatus = action.payload;
    },
    setDeregisterMachineError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.deregisterMachineError = action.payload;
    },
    setDeleteMachineLoadingStatus: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.deleteMachineLoadingStatus = action.payload;
    },
    setDeleteMachineError: (state, action: PayloadAction<string | null>) => {
      state.deleteMachineError = action.payload;
    },

    setGenerateAuthCodeResponse: (
      state,
      action: PayloadAction<GenerateAuthCodeResponse>,
    ) => {
      state.generateAuthCodeResponse = action.payload;
    },
    setGenerateAuthCodeLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.generateAuthCodeLoadingStatus = action.payload;
    },
    setGenerateAuthCodeError: (state, action: PayloadAction<string | null>) => {
      state.generateAuthCodeError = action.payload;
    },
  },
  selectors: {
    machinesListResponseSelector: (state) => state.machinesListResponse,
    isMachinesListLoadingSelector: (state) =>
      state.machinesListLoadingStatus === DataLoadingStatus.LOADING,
    machinesListErrorSelector: (state) =>
      state.machinesListLoadingStatus === DataLoadingStatus.ERROR
        ? state.machinesListError
        : null,
    isCreateMachineLoadingSelector: (state) =>
      state.createMachineLoadingStatus === DataLoadingStatus.LOADING,
    createMachineErrorSelector: (state) =>
      state.createMachineLoadingStatus === DataLoadingStatus.ERROR
        ? state.createMachineError
        : null,
    createMachineResponseSelector: (state) => state.createMachineResponse,
    isRegisterMachineLoadingSelector: (state) =>
      state.registerMachineLoadingStatus === DataLoadingStatus.LOADING,
    registerMachineErrorSelector: (state) =>
      state.registerMachineLoadingStatus === DataLoadingStatus.ERROR
        ? state.registerMachineError
        : null,
    registerMachineResponseSelector: (state) => state.registerMachineResponse,
    deregisterMachineLoadingStatusSelector: (state) =>
      state.deregisterMachineLoadingStatus,
    deregisterMachineErrorSelector: (state) =>
      state.deregisterMachineLoadingStatus === DataLoadingStatus.ERROR
        ? state.deregisterMachineError
        : null,
    deleteMachineLoadingStatusSelector: (state) =>
      state.deleteMachineLoadingStatus,
    deleteMachineErrorSelector: (state) =>
      state.deleteMachineLoadingStatus === DataLoadingStatus.ERROR
        ? state.deleteMachineError
        : null,

    generateAuthCodeResponseSelector: (state) => state.generateAuthCodeResponse,
    generateAuthCodeLoadingStatusSelector: (state) =>
      state.generateAuthCodeLoadingStatus,
    generateAuthCodeErrorSelector: (state) =>
      state.generateAuthCodeLoadingStatus === DataLoadingStatus.ERROR
        ? state.generateAuthCodeError
        : null,
  },
});

export const {
  setMachines,
  setMachinesListLoading,
  setMachinesListError,
  setCreateMachineLoading,
  setCreateMachineError,
  setCreateMachineResponse,
  setRegisterMachineResponse,
  setRegisterMachineLoading,
  setRegisterMachineError,
  setDeregisterMachineLoadingStatus,
  setDeregisterMachineError,
  setDeleteMachineLoadingStatus,
  setDeleteMachineError,
  setGenerateAuthCodeResponse,
  setGenerateAuthCodeLoading,
  setGenerateAuthCodeError,
} = machinesSlice.actions;

export const {
  machinesListResponseSelector,
  isMachinesListLoadingSelector,
  machinesListErrorSelector,
  isCreateMachineLoadingSelector,
  createMachineErrorSelector,
  createMachineResponseSelector,
  isRegisterMachineLoadingSelector,
  registerMachineErrorSelector,
  registerMachineResponseSelector,
  deregisterMachineLoadingStatusSelector,
  deregisterMachineErrorSelector,
  deleteMachineLoadingStatusSelector,
  deleteMachineErrorSelector,
  generateAuthCodeResponseSelector,
  generateAuthCodeLoadingStatusSelector,
  generateAuthCodeErrorSelector,
} = machinesSlice.selectors;

export default machinesSlice.reducer;
