import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkflowFiltersAction } from '../redux/actions/filter_options.action';
import {
  workflowFiltersErrorSelector,
  workflowFiltersLoadingSelector,
  workflowFiltersSelector,
} from '../redux/selectors/filter_options.selectors';
import { FEATURE_FLAGS } from '../utils/constants';
import useIsFeatureEnabled from './useIsFeatureEnabled';

export const useFetchWorkflowFilters = (
  orgResourceName?: string,
  checkAsPerUnifiedFeatureFlag?: boolean,
  // If this is true, then we will always use the unified workflows view irrespective of the feature flag
  alwaysUseUnifiedWorkflows?: boolean,
) => {
  const dispatch = useDispatch();

  const isUnifiedWorkflowsViewEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.UNIFIED_WORKFLOWS_VIEW,
  );

  const workflowFilters = useSelector(workflowFiltersSelector);
  const workflowFiltersLoading = useSelector(workflowFiltersLoadingSelector);
  const workflowFiltersError = useSelector(workflowFiltersErrorSelector);

  useEffect(() => {
    if (orgResourceName) {
      dispatch(
        getWorkflowFiltersAction(
          orgResourceName,
          checkAsPerUnifiedFeatureFlag && isUnifiedWorkflowsViewEnabled,
          // If this is true, then we will always use the unified workflows view irrespective of the feature flag
          alwaysUseUnifiedWorkflows,
        ),
      );
    }
  }, [
    orgResourceName,
    checkAsPerUnifiedFeatureFlag,
    isUnifiedWorkflowsViewEnabled,
  ]);

  return { workflowFilters, workflowFiltersLoading, workflowFiltersError };
};
