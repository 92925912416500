import { OrgInfo } from 'protos/pb/v1alpha1/user';
import { Token } from '../services/StorageService';
import {
  EXTENSION_ID,
  TOKEN_COOKIE_KEY_PLACEHOLDER,
} from 'extension/src/constants';
/**
 * Send the authenticated token to our Chrome extension, which is usually
 * started on the extension side by navigating to the /login?redirectUrl=/extension
 * page.
 *
 * Our Chrome extension would navigate to the extension dashboard page after
 * receiving the token, so orby-web-app doesn't need to have a separate page for
 * the extension login.
 *
 * If token == 'USE_COOKIE', extension will try to use refresh token from cookie
 */
export async function sendTokenToExtension(
  token: Token | undefined | typeof TOKEN_COOKIE_KEY_PLACEHOLDER,
) {
  await sendMessageToExtension({
    type: 'loginSuccessful',
    token,
  });
}

/**
 * Send the selected orgInfo to the extension from the web app
 */
export async function sendOrgInfoToExtension(orgInfo: OrgInfo) {
  await sendMessageToExtension({
    type: 'setOrgInfo',
    orgInfo,
  });
}

/**
 * Send the logout command to the extension from the web app
 */
export async function sendLogOutToExtension() {
  await sendMessageToExtension({
    type: 'logoutSuccessful',
  });
}

export async function sendMessageToExtension(message: {
  type: string;
  [key: string]: any;
}) {
  if (typeof chrome !== 'undefined' && chrome?.runtime) {
    await chrome.runtime.sendMessage(EXTENSION_ID, message);
  }
}
