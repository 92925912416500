import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import {
  CreateMachineRequest,
  CreateMachineResponse,
  DeleteMachineRequest,
  DeregisterMachineRequest,
  GenerateAuthCodeRequest,
  GenerateAuthCodeResponse,
  ListMachinesRequest,
  ListMachinesResponse,
  MachineIdentityServiceClientImpl,
  RegisterMachineRequest,
  RegisterMachineResponse,
} from 'protos/pb/v1alpha1/machine_identity_service';
import { storageService } from './StorageService';

export class MachinesService {
  private static _instance = new MachinesService();
  private _client: MachineIdentityServiceClientImpl;

  private constructor() {
    this._client = new MachineIdentityServiceClientImpl(rpcWithErrorHandling);
  }

  static getInstance(): MachinesService {
    if (!this._instance) {
      this._instance = new MachinesService();
    }
    return this._instance;
  }

  listMachines = async (
    req: ListMachinesRequest,
  ): Promise<{ response?: ListMachinesResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.ListMachines(
        {
          ...req,
          pageNumber: req.pageNumber ? req.pageNumber - 1 : 0, // The machine API expects pageNumber to be 0-indexed
        },
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  createMachine = async (
    req: CreateMachineRequest,
  ): Promise<{ response?: CreateMachineResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.CreateMachine(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  registerMachine = async (
    req: RegisterMachineRequest,
  ): Promise<{ response?: RegisterMachineResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.RegisterMachine(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  deregisterMachine = async (
    req: DeregisterMachineRequest,
  ): Promise<{ response?: boolean; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      await this._client.DeregisterMachine(req, getMetaData({ authorization }));
      return { response: true };
    } catch (error) {
      return { error: error as Error };
    }
  };

  deleteMachine = async (
    req: DeleteMachineRequest,
  ): Promise<{ response?: boolean; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      await this._client.DeleteMachine(req, getMetaData({ authorization }));
      return { response: true };
    } catch (error) {
      return { error: error as Error };
    }
  };

  generateAuthCode = async (
    req: GenerateAuthCodeRequest,
  ): Promise<{ response?: GenerateAuthCodeResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.GenerateAuthCode(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };
}

export const machinesService = MachinesService.getInstance();
