import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import OrbyLoginLogo from '../../static/orby.svg';
import { LoginForm } from './components/LoginForm';
import LoginBackground from '../../static/login-bg.gif';
import { useSelector, useDispatch } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import useLogoutHandler from '../../hooks/useLogoutHandler';
import { setRouterPath } from '../../redux/slices/router.slice';

const LoginPage: React.FC = () => {
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const user = useSelector(loggedInUserSelector);
  const { handleLogout } = useLogoutHandler();
  const [loggingOut, setLoggingOut] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouterPath('/login'));
  }, []);

  useEffect(() => {
    if (user && !selectedOrgInfo && !loggingOut) {
      setTimeout(() => {
        setLoggingOut(true);
        handleLogout();
      }, 5000);
    }
  }, [user, selectedOrgInfo, loggingOut]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1,
          width: '100%',
          height: '100%',
          background: `url(${LoginBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            marginBottom: '80px',
            marginTop: '80px',
            marginRight: '108px',
            textAlign: 'right',
          }}
        >
          <img src={OrbyLoginLogo} alt='Orby Logo' width={150} />
        </Box>
        <Box
          sx={{
            width: '440px',
          }}
        >
          <LoginForm />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(LoginPage);
