import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link } from 'react-router-dom';
import {
  Container,
  Box,
  IconButton,
  Divider,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as MicrosoftLogo } from '../../../static/icons/microsoft.svg';
import { ReactComponent as GoogleLogo } from '../../../static/icons/google.svg';
import {
  ENABLE_PASSWORD_BASED_LOGIN,
  GOOGLE_DEFAULT_SCOPE,
  IS_PRE_PROD,
  IS_PROD,
  MICROSOFT_DEFAULT_SCOPE,
} from '../../../utils/constants';
import { Token, storageService } from '../../../services/StorageService';
import {
  authLoginAction,
  authLoginErrorAction,
  googleAuthLoginErrorAction,
  googleSignInAction,
  microsoftAuthLoginErrorAction,
  microsoftSignInAction,
  samlLoginAction,
} from '../../../redux/actions/auth.action';
import { LoginRequest } from 'protos/pb/v1alpha1/users_service';
import {
  authErrorSelector,
  loadingSelector,
  loginErrorMessageSelector,
  tokenSelector,
} from '../../../redux/selectors/auth.selectors';
import { samlService } from '../../../services/SAMLService';
import {
  OrbyButton,
  OrbyColorPalette,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import { showErrorToast } from 'orby-ui/src/components/toast/OrbyToast';
import { isEmptyToken } from '../../../utils/Auth';
import { APP_ROUTES } from '../../ProtectedRoutes/Routes';
// ----------------------------------------------------------------------

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const token: Token | undefined = useSelector(tokenSelector);
  const loginErrorMessage: string | undefined = useSelector(
    loginErrorMessageSelector,
  );
  const authLoginError = useSelector(authErrorSelector);
  const SCOPE = GOOGLE_DEFAULT_SCOPE;

  const isPreprodOrProd = IS_PROD || IS_PRE_PROD;

  // This variable is added to add password based login support for e2e tests
  // since user can only login via google which cannot be done in e2e tests
  const [enablePasswordLogin, setEnablePasswordLogin] = useState(
    !!localStorage.getItem(ENABLE_PASSWORD_BASED_LOGIN),
  );

  const [showPassword, setShowPassword] = useState(false);
  const [isShowLoadingOverlay, setIsShowLoadingOverlay] = useState(false);

  const [isSamlRequestSent, setIsSamlRequestSent] = useState(false);

  useEffect(() => {
    if (isEmptyToken(token)) return;
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    const redirectUrl: string | null = transformUrl(params.get('redirectUrl'));
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      window.location.href = '/';
    }
  }, [token]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: enablePasswordLogin
      ? Yup.string().required('Password is required')
      : Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      preLoginClearStorage();
      // Setting the error message to undefined
      // once user clicks the login button again
      dispatch(googleAuthLoginErrorAction());
      dispatch(authLoginErrorAction(''));
      if (enablePasswordLogin) {
        const loginRequest: LoginRequest = {};
        loginRequest.email = values.email;
        loginRequest.password = values.password;
        dispatch(authLoginAction(loginRequest));
        return;
      }
      setIsSamlRequestSent(true);
      samlService
        .login(values.email)
        .then(() => storageService.setSamlRedirect())
        .catch((e) => {
          setIsSamlRequestSent(false);
          // shows password field upon any error code other than
          // 401 which basically means user is not in allowlist
          if (e.code !== 401) {
            setEnablePasswordLogin(true);
          } else {
            dispatch(authLoginErrorAction(e.message));
          }
        });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  // CLEAR ANY STORED INFO BEFORE PROCEEDING FOR NEW LOGIN
  const preLoginClearStorage = () => {
    storageService.deleteStoredValues();
  };

  const googleSignIn = () => {
    preLoginClearStorage();
    // Setting the error message to undefined
    // once user clicks the login button again
    dispatch(googleAuthLoginErrorAction());
    dispatch(googleSignInAction({ scope: SCOPE }));
  };

  const handleMicrosoftLogin = () => {
    preLoginClearStorage();
    // Setting the error message to undefined
    // once user clicks the login button again
    dispatch(microsoftAuthLoginErrorAction(''));
    dispatch(microsoftSignInAction({ scope: MICROSOFT_DEFAULT_SCOPE }));
  };

  const transformUrl = (path: string | null) => {
    if (path === APP_ROUTES.WORKFLOW_CREATE) {
      return APP_ROUTES.WORKFLOW;
    }
    return path;
  };

  const handleSamlRedirect = async () => {
    try {
      const message = 'Login failed, reach out to admin for more details.';
      const cookie = await samlService.checkForSamlResponse(message);
      if (!!cookie && 'access_token' in cookie) {
        dispatch(
          samlLoginAction({
            access_token: cookie.access_token,
            session_id: cookie.session_id,
          }),
        );
      } else {
        setIsShowLoadingOverlay(false);
        if (cookie && 'error' in cookie) {
          showErrorToast(cookie.error);
          return;
        }
        showErrorToast(message);
      }
    } catch (e) {
      setIsShowLoadingOverlay(false);
      showErrorToast(e as string);
    }
  };

  // We use useLayoutEffect instead of useEffect because, after SAML login,
  // the login form briefly appears when redirecting back.
  // To prevent the login form from showing at all, we use useLayoutEffect.

  useLayoutEffect(() => {
    // Checks for saml redirect
    if (storageService.isSamlRedirect()) {
      setIsShowLoadingOverlay(true);
      handleSamlRedirect();
    }
  }, []);

  useEffect(() => {
    if (authLoginError) {
      setIsShowLoadingOverlay(false);
    }
  }, [authLoginError]);

  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
      }}
    >
      <Backdrop
        transitionDuration={0}
        sx={{
          backgroundColor: OrbyColorPalette['purple-700'],
          color: OrbyColorPalette['white-0'],
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isShowLoadingOverlay}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <OrbyTypography
          sx={{ align: 'center', marginBottom: '36px' }}
          size={'display-xs'}
          weight='medium'
          color={OrbyColorPalette['black-0']}
        >
          Sign in to your account
        </OrbyTypography>

        {!isPreprodOrProd && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <OrbyTypography
              size={'md'}
              weight='medium'
              color={OrbyColorPalette['black-0']}
              sx={{ marginBottom: '60px', align: 'center' }}
            >
              Don&apos;t have an account?
            </OrbyTypography>
            <Link
              style={{ marginBottom: '60px' }}
              to={'/sign-up'}
              id={'sign-up-link'}
              className='focused-link'
            >
              <OrbyTypography
                weight='bold'
                color={OrbyColorPalette['blue-700']}
                sx={{ align: 'center' }}
              >
                &emsp;Get Started
              </OrbyTypography>
            </Link>
          </Box>
        )}

        <FormikProvider value={formik}>
          <Form
            style={{ width: '100%' }}
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Box sx={{ width: '100%', pt: isPreprodOrProd ? '20px' : '0px' }}>
              <OrbyTextField
                containerSx={{ marginBottom: '16px' }}
                label='Email address'
                placeholder='Enter your email'
                width='100%'
                autoComplete='email'
                type='email'
                {...getFieldProps('email')}
                error={touched.email && errors.email ? errors.email : ''}
              />

              {enablePasswordLogin && (
                <OrbyTextField
                  containerSx={{ marginBottom: '16px' }}
                  label='Password'
                  width='100%'
                  autoComplete='current-password'
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <IconButton
                      onClick={handleShowPassword}
                      edge='end'
                      aria-label={
                        showPassword ? 'Hide Password' : 'Show Password'
                      }
                    >
                      <Icon
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  }
                  {...getFieldProps('password')}
                  error={
                    touched.password && errors.password ? errors.password : ''
                  }
                />
              )}

              {!isPreprodOrProd && (
                <Box marginTop='16px'>
                  <Link
                    to={'/forgot-password'}
                    id={'forgot-password-link'}
                    className='focused-link'
                    style={{
                      textDecorationColor: '#757575',
                    }}
                  >
                    <OrbyTypography
                      weight={'bold'}
                      color={OrbyColorPalette['black-0']}
                      sx={{ textAlign: 'right' }}
                    >
                      Forgot Password?
                    </OrbyTypography>
                  </Link>
                </Box>
              )}

              <Box sx={{ width: '100%', marginTop: '24px' }}>
                <OrbyButton
                  type='submit'
                  variant='primary-contained-black'
                  disabled={loading || isSamlRequestSent}
                  size='large'
                  sx={{
                    width: '100%',
                  }}
                  label={
                    loading || isSamlRequestSent ? 'Loading...' : 'Sign in'
                  }
                  ariaLabel={'Sign in'}
                />
              </Box>
              <Divider
                sx={{
                  marginTop: '36px',
                  marginBottom: '36px',
                  '&::before, &::after': {
                    borderColor: OrbyColorPalette['grey-300'],
                    borderWidth: '.5px',
                  },
                }}
              >
                <OrbyTypography
                  size='sm'
                  weight='regular'
                  color={OrbyColorPalette['grey-600']}
                >
                  OR
                </OrbyTypography>
              </Divider>
            </Box>
          </Form>
        </FormikProvider>

        {/* GOOGLE-MICROSOFT-LOGIN-BUTTON */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <OrbyButton
            onClick={() => googleSignIn()}
            variant='monochrome-outline'
            ariaLabel='Sign in with Google'
            sx={{
              width: '100%',
              padding: '10px, 12px, 10px, 12px',
              height: '44px',
              border: `1px solid ${OrbyColorPalette['grey-200']}`,
              '&:hover': {
                background: 'rgba(22, 105, 247, 0.04)',
              },
              '&:focus': {
                background: 'rgba(22, 105, 247, 0.04)',
              },
              color: OrbyColorPalette['grey-700'],
            }}
            startIcon={<GoogleLogo width={20} />}
            label='Sign in with Google'
          />

          <OrbyButton
            onClick={handleMicrosoftLogin}
            variant='monochrome-outline'
            sx={{
              width: '100%',
              padding: '10px, 12px, 10px, 12px',
              height: '44px',
              border: `1px solid ${OrbyColorPalette['grey-200']}`,
              '&:hover': {
                background: 'rgba(22, 105, 247, 0.04)',
              },
              '&:focus': {
                background: 'rgba(22, 105, 247, 0.04)',
              },
              color: OrbyColorPalette['grey-700'],
            }}
            startIcon={<MicrosoftLogo width={20} />}
            label='Sign in with Microsoft'
            ariaLabel={'Sign in with Microsoft'}
          />
        </Box>

        {(loginErrorMessage || authLoginError) && (
          <OrbyTypography
            aria-live='assertive'
            sx={{
              paddingTop: '10px',
              align: 'center',
            }}
            color={OrbyColorPalette['error-500']}
          >
            {loginErrorMessage || authLoginError}
          </OrbyTypography>
        )}

        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          marginTop={'36px'}
        >
          <Link
            style={{
              color: OrbyColorPalette['black-0'],
              fontSize: '14px',
              fontWeight: '600',
              marginRight: '15px',
            }}
            id={'privacy-policy-link'}
            className='focused-link'
            to='https://orby.ai/privacy-policy'
            target='_blank'
            rel='noreferrer'
          >
            Privacy policy{' '}
          </Link>
          <Link
            style={{
              color: OrbyColorPalette['black-0'],
              fontSize: '14px',
              fontWeight: 600,
              marginRight: '15px',
            }}
            className='focused-link'
            id={'terms-of-service-link'}
            to='https://orby.ai/terms-of-service'
            target='_blank'
            rel='noreferrer'
          >
            Terms of use
          </Link>
        </Box>
      </Box>
    </Container>
  );
};
