import { createSlice, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DataLoadingStatus } from '../../utils/constants';
import {
  CreateWebhookRequest,
  DeleteWebhookRequest,
  ListWebhooksRequest,
  ListWebhooksResponse,
  UpdateWebhookRequest,
} from 'protos/pb/v1alpha2/webhook_service';

export interface WebhooksState {
  webhooksListResponse: ListWebhooksResponse | null;
  webhooksListLoadingStatus: DataLoadingStatus;
  webhooksListError: string | null;

  createWebhookLoadingStatus: DataLoadingStatus;
  createWebhookError: string | null;

  updateWebhookLoadingStatus: DataLoadingStatus;
  updateWebhookError: string | null;

  deleteWebhookLoadingStatus: DataLoadingStatus;
  deleteWebhookError: string | null;
}

const initialState: WebhooksState = {
  webhooksListResponse: null,
  webhooksListLoadingStatus: DataLoadingStatus.INITIAL,
  webhooksListError: null,

  createWebhookLoadingStatus: DataLoadingStatus.INITIAL,
  createWebhookError: null,

  updateWebhookLoadingStatus: DataLoadingStatus.INITIAL,
  updateWebhookError: null,

  deleteWebhookLoadingStatus: DataLoadingStatus.INITIAL,
  deleteWebhookError: null,
};

/**
 * The actions are created here to be used in the machines.saga.ts file.
 */

export const createWebhooks = createAction<CreateWebhookRequest>(
  'webhooks/createWebhook',
);

export const updateWebhooks = createAction<UpdateWebhookRequest>(
  'webhooks/updateWebhook',
);

export const deleteWebhooks = createAction<DeleteWebhookRequest>(
  'webhooks/deleteWebhook',
);

export const fetchWebhooks = createAction<ListWebhooksRequest>(
  'webhooks/fetchWebhooks',
);

export const webhooksSlice = createSlice({
  name: 'webhooks',
  initialState,

  reducers: {
    setWebhooks: (state, action: PayloadAction<ListWebhooksResponse>) => {
      state.webhooksListResponse = action.payload;
    },
    setWebhooksListLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.webhooksListLoadingStatus = action.payload;
    },
    setWebhooksListError: (state, action: PayloadAction<string | null>) => {
      state.webhooksListError = action.payload;
    },

    setCreateWebhookLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.createWebhookLoadingStatus = action.payload;
    },
    setCreateWebhookError: (state, action: PayloadAction<string | null>) => {
      state.createWebhookError = action.payload;
    },

    setUpdateWebhookLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.updateWebhookLoadingStatus = action.payload;
    },
    setUpdateWebhookError: (state, action: PayloadAction<string | null>) => {
      state.updateWebhookError = action.payload;
    },

    setDeleteWebhookLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.deleteWebhookLoadingStatus = action.payload;
    },
    setDeleteWebhookError: (state, action: PayloadAction<string | null>) => {
      state.deleteWebhookError = action.payload;
    },
  },
  selectors: {
    webhooksListResponseSelector: (state) => state.webhooksListResponse,
    isWebhooksListLoadingSelector: (state) =>
      state.webhooksListLoadingStatus === DataLoadingStatus.LOADING,
    webhooksListErrorSelector: (state) =>
      state.webhooksListLoadingStatus === DataLoadingStatus.ERROR
        ? state.webhooksListError
        : null,

    isCreateWebhookLoadingSelector: (state) =>
      state.createWebhookLoadingStatus === DataLoadingStatus.LOADING,
    createWebhookSuccessSelector: (state) =>
      state.createWebhookLoadingStatus === DataLoadingStatus.LOADED,
    createWebhookErrorSelector: (state) =>
      state.createWebhookLoadingStatus === DataLoadingStatus.ERROR
        ? state.createWebhookError
        : null,

    isUpdateWebhookLoadingSelector: (state) =>
      state.updateWebhookLoadingStatus === DataLoadingStatus.LOADING,
    updateWebhookSuccessSelector: (state) =>
      state.updateWebhookLoadingStatus === DataLoadingStatus.LOADED,
    updateWebhookErrorSelector: (state) =>
      state.updateWebhookLoadingStatus === DataLoadingStatus.ERROR
        ? state.updateWebhookError
        : null,

    isDeleteWebhookLoadingSelector: (state) =>
      state.deleteWebhookLoadingStatus === DataLoadingStatus.LOADING,
    deleteWebhookSuccessSelector: (state) =>
      state.deleteWebhookLoadingStatus === DataLoadingStatus.LOADED,
    deleteWebhookErrorSelector: (state) =>
      state.deleteWebhookLoadingStatus === DataLoadingStatus.ERROR
        ? state.deleteWebhookError
        : null,
  },
});

export const {
  setWebhooks,
  setWebhooksListLoading,
  setWebhooksListError,
  setCreateWebhookLoading,
  setCreateWebhookError,
  setUpdateWebhookLoading,
  setUpdateWebhookError,
  setDeleteWebhookLoading,
  setDeleteWebhookError,
} = webhooksSlice.actions;

export const {
  webhooksListResponseSelector,
  isWebhooksListLoadingSelector,
  webhooksListErrorSelector,
  isCreateWebhookLoadingSelector,
  createWebhookSuccessSelector,
  createWebhookErrorSelector,
  isUpdateWebhookLoadingSelector,
  updateWebhookSuccessSelector,
  updateWebhookErrorSelector,
  isDeleteWebhookLoadingSelector,
  deleteWebhookSuccessSelector,
  deleteWebhookErrorSelector,
} = webhooksSlice.selectors;

export default webhooksSlice.reducer;
