// Utility functions specifically for dealing with Orbot workflows
// WorkflowUtils.ts is for Orby workflows

import { Action } from 'protos/pb/v1alpha1/orbot_action';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { SecretBlock, SecretValue } from 'protos/pb/v1alpha1/secret_manager';
import { getActionById } from 'workflow-utils/src/workflow';

/**
 * Updates secret IDs in a workflow based on a provided secret block.
 *
 * This function traverses the workflow's actions and replaces secret values
 * (prefixed with '#') with their corresponding secret IDs from the secret block.
 *
 * @param secretBlock - The SecretBlock containing secret information
 * @param rootActionIDs - A set of root action IDs to start the traversal
 * @param workflow - The OrbotWorkflow to be updated
 * @returns A boolean indicating whether any updates were made to the workflow
 */

export function updateSecretIDsInWorkflow(
  secretBlock: SecretBlock,
  rootActionIDs: Set<string>,
  workflow: Workflow,
): boolean {
  const newSecretBlockId = secretBlock.id;
  /**
   * Recursively updates secret values in the action tree.
   *
   * This function traverses the action and its child actions, replacing
   * secret values with their corresponding secret IDs from the secret block.
   *
   * @returns true if the action or any of its children were updated
   * @param action - The action to update
   */
  function updateSecretValuesRecursively(action: Action): boolean {
    let updated = false;

    if (action.setValue?.fieldValue?.legacySecretValue?.startsWith('#')) {
      const newSecretId = secretBlock.secretInfos?.find(
        (secretInfo) =>
          secretInfo.secret?.displayName ===
          action.setValue?.fieldValue?.legacySecretValue?.substring(1),
      )?.secret?.id;

      if (
        newSecretId &&
        action.setValue.fieldValue.legacySecretValue !== newSecretId
      ) {
        action.setValue.fieldValue.legacySecretValue = newSecretId;
        updated = true;
      }
    }

    if (action.setValue?.fieldValue?.secretValue) {
      updated ||= updateSecretValue(action.setValue.fieldValue.secretValue);
    }

    if (action.getPasscode?.totp?.secret?.secretValue) {
      updated ||= updateSecretValue(action.getPasscode.totp.secret.secretValue);
    }

    if (action.getPasscode?.hotp?.secret?.secretValue) {
      updated ||= updateSecretValue(action.getPasscode.hotp.secret.secretValue);
    }

    for (const childAction of action.block?.actions ?? []) {
      updated = updateSecretValuesRecursively(childAction) || updated;
    }

    for (const prerequisite of action.prerequisites ?? []) {
      updated = updateSecretValuesRecursively(prerequisite.action!) || updated;
    }

    for (const thenAction of action.condition?.thenActions ?? []) {
      updated = updateSecretValuesRecursively(thenAction) || updated;
    }

    for (const elseAction of action.condition?.elseActions ?? []) {
      updated = updateSecretValuesRecursively(elseAction) || updated;
    }

    for (const loopAction of action.foreach?.loopActions ?? []) {
      updated = updateSecretValuesRecursively(loopAction) || updated;
    }

    return updated;
  }

  function updateSecretValue(secretValue: SecretValue): boolean {
    let updated = false;

    if (
      newSecretBlockId &&
      (!secretValue.blockId || secretValue.blockId !== newSecretBlockId)
    ) {
      secretValue.blockId = newSecretBlockId;
      updated = true;
    }
    return updated;
  }

  let workflowUpdated = false;

  for (const actionID of rootActionIDs) {
    let rootAction: Action | undefined;
    let found = false;
    // there might be actions sharing the same ID between different processes,
    // and we need to make sure all those actions are updated.
    for (const process of workflow.processes ?? []) {
      rootAction = getActionById(actionID, process.actions ?? []);
      if (rootAction) {
        found = true;
        workflowUpdated =
          updateSecretValuesRecursively(rootAction) || workflowUpdated;
      }
    }
    if (!found) {
      console.error(`Actions with IDs ${actionID} not found in the workflow`);
    }
  }

  return workflowUpdated;
}

// Recursively check if an action, or any of its children, contain a secret
// by recursing into its actionBlock. Make sure to also check all branches
// Then, add the secret ID to the set
const getSecretValuesFromAction = (action: Action): Set<string> => {
  const secretValues = new Set<string>();

  if (action.setValue?.fieldValue?.legacySecretValue) {
    secretValues.add(action.setValue.fieldValue.legacySecretValue);
  }

  // If action has a secret value, add it to the set, (since the legacySecretValue
  // is deprecated)
  // There is a chance that secretValue.blockId is not set, so we are checking name instead
  if (action?.setValue?.fieldValue?.secretValue) {
    secretValues.add(action.setValue.fieldValue.secretValue?.name || '');
  }

  if (action.getPasscode?.totp?.secret?.secretValue) {
    secretValues.add(action.getPasscode.totp.secret.secretValue.name || '');
  }

  if (action.getPasscode?.hotp?.secret?.secretValue) {
    secretValues.add(action.getPasscode.hotp.secret.secretValue.name || '');
  }

  if (action.block?.actions) {
    for (const childAction of action.block.actions) {
      const childSecrets = getSecretValuesFromAction(childAction);
      childSecrets.forEach((secret) => secretValues.add(secret));
    }
  }

  for (const prerequisite of action.prerequisites ?? []) {
    const prerequisiteSecrets = getSecretValuesFromAction(prerequisite.action!);
    prerequisiteSecrets.forEach((secret) => secretValues.add(secret));
  }
  for (const thenAction of action.condition?.thenActions ?? []) {
    const thenBranchSecrets = getSecretValuesFromAction(thenAction);
    thenBranchSecrets.forEach((secret) => secretValues.add(secret));
  }
  for (const elseAction of action.condition?.elseActions ?? []) {
    const elseBranchSecrets = getSecretValuesFromAction(elseAction);
    elseBranchSecrets.forEach((secret) => secretValues.add(secret));
  }

  for (const loopAction of action.foreach?.loopActions ?? []) {
    const loopBranchSecrets = getSecretValuesFromAction(loopAction);
    loopBranchSecrets.forEach((secret) => secretValues.add(secret));
  }

  for (const blockAction of action.block?.actions ?? []) {
    const blockBranchSecrets = getSecretValuesFromAction(blockAction);
    blockBranchSecrets.forEach((secret) => secretValues.add(secret));
  }

  return secretValues;
};

export function getSecretBlockToActionIdMap(
  workflow: Workflow,
): Record<string, { rootActionIDs: Set<string>; secretValues: Set<string> }> {
  const secretBlockToActionIdMap: Record<
    string,
    { rootActionIDs: Set<string>; secretValues: Set<string> }
  > = {};
  let currentGoto = '';
  for (const process of workflow.processes || []) {
    for (const rootAction of process.actions || []) {
      // TODO: Maybe we have to traverse the AST to check for a goto action
      // The goto might be nested
      if (rootAction.goto?.url?.jsonValue) {
        const parsedGoToUrl = JSON.parse(rootAction.goto.url.jsonValue);
        currentGoto = new URL(parsedGoToUrl).hostname;
      }

      const secretValues = getSecretValuesFromAction(rootAction);
      if (secretValues.size > 0) {
        if (!secretBlockToActionIdMap[currentGoto]) {
          secretBlockToActionIdMap[currentGoto] = {
            rootActionIDs: new Set<string>(),
            secretValues: new Set<string>(),
          };
        }
        secretValues.forEach((secret) =>
          secretBlockToActionIdMap[currentGoto].secretValues.add(secret),
        );
        secretBlockToActionIdMap[currentGoto].rootActionIDs.add(
          rootAction.id || '',
        );
      }
    }
  }
  return secretBlockToActionIdMap;
}
