import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
  useParams,
  matchPath,
} from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useIsFeatureEnabled from '../../hooks/useIsFeatureEnabled';
import {
  LazyTasks,
  LazyExecutionHistory,
  LazyUiAutomationsDetail,
  LazyCreateWorkflowTemplate,
  LazyDashboard,
  LazyDebuggerReview,
  LazyIntegrationTest,
  LazyMyWorkflows,
  LazyWorkflowDetails,
  LazyUpdateWorkflow,
  LazyTrashCan,
  LazyFieldsPage,
  LazyCreateWorkflow,
  LazyTemplate,
  LazyCreateConnector,
  LazyUpdateConnector,
  LazySettings,
  LazyConfigureSSO,
  LazyScheduledTasks,
  LazyTeamsPage,
  LazyFeatureFlags,
  LazyFeatureFlagCreationPage,
  LazyFeatureFlagUpdatePage,
  LazyTasksExport,
  LazyCompanyNonWorkingDays,
  LazyMachines,
  LazyTaskReviewDetails,
  LazyProcessDiscovery,
  LazyProcessDiscoveryDetails,
  LazyCreateProcessDiscovery,
  LazyWebhooks,
  LazyApiKeysManagement,
  LazyExecutions,
} from '.';
import WebDrawerWrapper from '../../components/web-drawer/WebDrawerWrapper';
import { setSelectedOrgInfo } from '../../redux/actions/user.action';
import {
  loggedInUserSelector,
  selectedOrgIdSelector,
  selectedOrgInfoCompletedSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';
import { storageService, Token } from '../../services/StorageService';
import {
  FEATURE_FLAGS,
  SESSION_BROADCAST_ACTIONS,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  IS_PROD,
} from '../../utils/constants';
import { isAdmin, isOrbyAIUser } from '../../utils/helpers';
import AnnouncementPopup from '../Announcement/AnnouncementPopup';
import IdleSessionManager from '../IdleSessionManager/IdleSessionManager';
import SessionTimeoutManager from '../SessionTimeout/SessionTimeoutManager';
import SuspenseLoader from './components/SuspenseLoader';
import { APP_ROUTES } from './Routes';
import { shouldCollapseSideDrawer } from './RouteBuilder.helpers';
import { amplitudeService } from '../../services/AmplitudeService';
import { setRouterPath } from '../../redux/slices/router.slice';
import { sendOrgInfoToExtension } from '../../utils/extension';
import { showErrorToast } from 'orby-ui/src/components/toast/OrbyToast';

interface Props {
  orbotWorkflowLength: number;
}
interface RouteConfig {
  path: string;
  element: ReactNode;
  enabled: boolean;
}

const TrackedRoute: FC<{ children: ReactNode; routerPath: string }> = ({
  children,
  routerPath,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (routerPath !== '*') {
      amplitudeService.trackPageView({
        path: location.pathname,
        location: window.location.href,
        router_path: routerPath,
        window_width: window.innerWidth,
        window_height: window.innerHeight,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
      });
      dispatch(setRouterPath(routerPath));
    }
  }, [routerPath]);
  return <>{children}</>;
};

const ProtectedRoutes: FC<Props> = ({ orbotWorkflowLength }) => {
  // HELPER FUNCTIONS

  const handlePostLoginToken = async (isLoginCookieEnabled: boolean) => {
    const token: Token | undefined = await storageService.getStoredToken();

    if (token?.refreshTokenExpiresAt) {
      // Note: due to extended_refresh_token feature flag we get refreshTokenExpiresAt for 90 days
      // But actual session only last for 24 hrs
      storageService.setSessionExpirationTime(token.refreshTokenExpiresAt);
    }

    if (isLoginCookieEnabled && token) {
      // Set the sessionId in the session storage
      await storageService.setSessionId(token.sessionId as string);

      // We need to remove the tokens from the local storage
      localStorage.removeItem('token');
      localStorage.removeItem('google-token');
    } else if (!token && !isLoginCookieEnabled) {
      // TODO: Implement login cookie logic - Need to fetch the token from BE and set it in localStorage
      // https://orby-ai.atlassian.net/browse/OA-3861
    }

    // If this page was opened for re-authentication, close the window
    // This is typically used when the user needs to log in again in a new window

    // check if this window was opened by a script using the window.open()
    if (window.opener && window.name === 'reauthenticate') {
      const broadcastChannel = new BroadcastChannel('SessionLogoutChannel');
      broadcastChannel.postMessage({
        type: SESSION_BROADCAST_ACTIONS.SYNC_SESSION_STATE,
        visibility: false,
        isReAuthenticated: true,
      });
      // This method can only be called on windows that were opened
      // by a script using the Window.open()
      // ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/close
      window.close();
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // STATES & SELECTORS

  // SIDE DRAWER COLLAPSE STATE
  const [collapse, setCollapse] = useState(
    shouldCollapseSideDrawer(location.pathname),
  );

  const user = useSelector(loggedInUserSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const userPermissions = useSelector(userPermissionsSelector);
  const selectedOrgInfoCompleted = useSelector(
    selectedOrgInfoCompletedSelector,
  );

  // send the selectedOrgInfo to the extension whenever there are any changes in selected org.
  useEffect(() => {
    if (selectedOrgInfo && selectedOrgInfoCompleted) {
      sendOrgInfoToExtension(selectedOrgInfo);
    }
  }, [selectedOrgInfoCompleted]);
  // FEATURE FLAGS

  const isLoginCookieEnabled = useIsFeatureEnabled(FEATURE_FLAGS.COOKIE);
  const isRbacEnabled = useIsFeatureEnabled(FEATURE_FLAGS.RBAC);

  const isAdvancedEditorEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.ADVANCED_WORKFLOW_EDITOR,
  );
  const isIdleSessionManagerEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.ENABLE_IDLE_SESSION_TIMEOUT,
  );
  const overrideSessionTimeoutTo1Min = useIsFeatureEnabled(
    FEATURE_FLAGS.OVERRIDE_IDLE_SESSION_TIMEOUT_1MIN,
  );
  const isWebhooksEnabled = useIsFeatureEnabled(FEATURE_FLAGS.WEBHOOK_ENABLED);
  const isApiKeysManagementEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.API_KEYS_MANAGEMENT,
  );

  // PERMISSIONS & CHECKS

  let isTestUser = false;
  if (user?.email === process.env.REACT_APP_TEST_USER) {
    isTestUser = true;
  }
  const isAdminUser = isAdmin(selectedOrgInfo?.role);
  const isOrbyAdmin =
    selectedOrgInfo?.orgDisplayName === 'Orby AI' && isAdminUser;
  const canCreateWorkflow =
    isAdminUser || (isRbacEnabled && userPermissions.createWorkflow);
  const canListArchivedResources =
    isAdminUser || (isRbacEnabled && userPermissions.listArchivedResources);
  const canAccessConnectors =
    isAdminUser || (isRbacEnabled && userPermissions.listConnectors);
  const canAccessWorkflowPage =
    canCreateWorkflow ||
    orbotWorkflowLength > 0 ||
    (isRbacEnabled &&
      (userPermissions.listWorkflows || userPermissions.listConnectors));
  const canAccessExecutionPage =
    isAdminUser ||
    orbotWorkflowLength > 0 ||
    (isRbacEnabled && userPermissions.listWorkflows);
  const canAccessTeamPage =
    isAdminUser || (isRbacEnabled && userPermissions.updateUser);
  const canAccessWebhooksPage = isAdminUser && isWebhooksEnabled;
  const canAccessApiKeysManagementPage =
    isAdminUser && isApiKeysManagementEnabled;

  // ROUTES CONFIGURATION
  // TODO: Move all these routes to a separate file
  const PROTECTED_ROUTES: RouteConfig[] = [
    {
      path: APP_ROUTES.DASHBOARD,
      element: <LazyDashboard />,
      enabled: true,
    },
    {
      path: APP_ROUTES.TASKS,
      element: <LazyTasks sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.PROCESS_DISCOVERY,
      element: <LazyProcessDiscovery sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.PROCESS_DISCOVERY_DIAGRAM,
      element: <LazyProcessDiscoveryDetails sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.PROCESS_DISCOVERY_DOCUMENTATION,
      element: <LazyProcessDiscoveryDetails sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.CREATE_PROCESS_DISCOVERY,
      element: <LazyCreateProcessDiscovery sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.TASKS_EXPORT,
      element: <LazyTasksExport />,
      enabled: true, // Authorization is handled by the server
    },
    {
      path: APP_ROUTES.ALL_EXECUTIONS,
      element: <LazyExecutions sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.EXECUTIONS,
      element: <LazyExecutionHistory sideDrawerCollapse={collapse} />,
      enabled: canAccessExecutionPage,
    },
    {
      path: APP_ROUTES.EXECUTION_DETAILS,
      element: <LazyUiAutomationsDetail />,
      enabled: canAccessExecutionPage,
    },
    {
      path: APP_ROUTES.WORKFLOW_TEMPLATE,
      element: <LazyCreateWorkflowTemplate />,
      enabled: canCreateWorkflow,
    },
    {
      path: APP_ROUTES.TASK_REVIEW,
      element: <LazyTaskReviewDetails sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.TASK_REVIEW_LEGACY,
      element: <LegacyTaskReviewRedirect />,
      enabled: true,
    },
    {
      path: APP_ROUTES.HITL_DETAILS,
      element: (
        <LazyTaskReviewDetails sideDrawerCollapse={collapse} isOrbot={true} />
      ),
      enabled: true,
    },
    {
      path: APP_ROUTES.DEV_TESTING,
      element: <LazyDebuggerReview />,
      enabled: isOrbyAIUser(user),
    },
    {
      path: APP_ROUTES.DEV_TESTING_DOCUMENT,
      element: (
        <LazyTaskReviewDetails debug={true} sideDrawerCollapse={collapse} />
      ),
      enabled: isOrbyAIUser(user),
    },
    {
      path: APP_ROUTES.INTEGRATION_TEST,
      element: <LazyIntegrationTest />,
      enabled: isTestUser,
    },
    {
      path: APP_ROUTES.WORKFLOW,
      element: <LazyMyWorkflows sideDrawerCollapse={collapse} />,
      enabled: canAccessWorkflowPage,
    },
    {
      path: APP_ROUTES.WORKFLOW_DEFINITION,
      element: <LazyWorkflowDetails sideDrawerCollapse={collapse} />,
      enabled: canAccessWorkflowPage,
    },
    {
      path: APP_ROUTES.WORKFLOW_UPDATE,
      element: <LazyUpdateWorkflow />,
      enabled: canAccessWorkflowPage,
    },
    {
      path: APP_ROUTES.WORKFLOW_CREATE,
      element: <LazyCreateWorkflow />,
      enabled: canCreateWorkflow,
    },
    {
      path: APP_ROUTES.WORKFLOW_SETTINGS,
      element: <LazyWorkflowDetails sideDrawerCollapse={collapse} />,
      enabled: canAccessWorkflowPage,
    },
    {
      path: APP_ROUTES.WORKFLOW_ADMINS,
      element: <LazyWorkflowDetails sideDrawerCollapse={collapse} />,
      enabled: canAccessWorkflowPage,
    },
    {
      path: APP_ROUTES.WORKFLOW_SCHEDULE,
      element: <LazyWorkflowDetails sideDrawerCollapse={collapse} />,
      enabled: canAccessWorkflowPage,
    },
    {
      path: APP_ROUTES.WORKFLOW_ADVANCED_EDITOR,
      element: <LazyWorkflowDetails sideDrawerCollapse={collapse} />,
      enabled: canAccessWorkflowPage && isAdvancedEditorEnabled,
    },
    {
      path: APP_ROUTES.CONNECTOR_CREATE,
      element: <LazyCreateConnector />,
      enabled: canAccessConnectors,
    },
    {
      path: APP_ROUTES.CONNECTOR_UPDATE,
      element: <LazyUpdateConnector />,
      enabled: canAccessConnectors,
    },
    {
      path: APP_ROUTES.DELETED_ITEMS,
      element: <LazyTrashCan sideDrawerCollapse={collapse} />,
      enabled: canListArchivedResources,
    },
    {
      path: APP_ROUTES.FIELDS,
      element: <LazyFieldsPage sideDrawerCollapse={collapse} />,
      enabled: true,
    },
    {
      path: APP_ROUTES.TEMPLATE_DEFINITION,
      element: <LazyTemplate />,
      enabled: canCreateWorkflow,
    },
    {
      path: APP_ROUTES.SETTINGS,
      element: <LazySettings />,
      enabled: isAdminUser,
    },
    {
      path: APP_ROUTES.CONFIGURE_SSO,
      element: <LazyConfigureSSO />,
      enabled: isAdminUser,
    },
    {
      path: APP_ROUTES.COMPANY_NON_WORKING_DAYS,
      element: <LazyCompanyNonWorkingDays />,
      enabled: isAdminUser,
    },
    {
      path: APP_ROUTES.MACHINES,
      element: <LazyMachines />,
      enabled: isAdminUser,
    },
    {
      path: APP_ROUTES.WEBHOOKS,
      element: <LazyWebhooks />,
      enabled: canAccessWebhooksPage,
    },
    {
      path: APP_ROUTES.API_KEYS_MANAGEMENT,
      element: <LazyApiKeysManagement />,
      enabled: canAccessApiKeysManagementPage,
    },
    {
      path: APP_ROUTES.SCHEDULED_TASKS,
      element: <LazyScheduledTasks />,
      enabled: isAdminUser && !IS_PROD,
    },
    {
      path: APP_ROUTES.TEAMS,
      element: <LazyTeamsPage sideDrawerCollapse={collapse} />,
      enabled: canAccessTeamPage,
    },
    {
      path: APP_ROUTES.FEATURE_FLAGS,
      element: <LazyFeatureFlags />,
      enabled: isOrbyAdmin,
    },
    {
      path: APP_ROUTES.FEATURE_FLAGS_CREATE,
      element: <LazyFeatureFlagCreationPage />,
      enabled: isOrbyAdmin,
    },
    {
      path: APP_ROUTES.FEATURE_FLAGS_UPDATE,
      element: <LazyFeatureFlagUpdatePage />,
      enabled: isOrbyAdmin,
    },
    {
      path: '*',
      element: <Navigate to={APP_ROUTES.DASHBOARD} />,
      enabled: true,
    },
  ];

  /**
   * USE EFFECTS
   */

  useEffect(() => {
    // if the current path is a collapsed path, set the collapse state to true
    // otherwise we keep the previous state
    if (shouldCollapseSideDrawer(location.pathname)) {
      setCollapse(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoginCookieEnabled !== undefined) {
      handlePostLoginToken(isLoginCookieEnabled);
    }
  }, [isLoginCookieEnabled]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.key === 'org-resource-name' &&
        event.newValue &&
        event.oldValue
      ) {
        const orgInfo = user?.orgInfos?.find(
          (org) => org.orgResourceName === event.newValue,
        );
        if (orgInfo) {
          dispatch(setSelectedOrgInfo(orgInfo));
          setTimeout(() => {
            navigate(
              { pathname: '/' },
              // prevent HITL unsaved changes warning
              { state: { preventBlocking: true } },
            ); // navigate to dashboard
          }, 100);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener when component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // Empty dependency array since we want this to run once on mount

  // Add this effect to handle org switching
  useEffect(() => {
    const match = matchPath(
      '/organisations/:organisation_id/*',
      location.pathname,
    );
    const organizationId = match?.params?.organisation_id;

    if (!organizationId) return;

    const targetOrgResourceName = `organizations/${organizationId}`;

    // Skip if already on correct org
    if (selectedOrgInfo?.orgResourceName === targetOrgResourceName) return;

    // Find matching org
    const targetOrganization = user?.orgInfos?.find(
      (org) => org.orgResourceName === targetOrgResourceName,
    );

    if (targetOrganization) {
      dispatch(setSelectedOrgInfo(targetOrganization));
    } else {
      showErrorToast("You don't have permissions to view this organization", {
        position: 'top-right',
        autoClose: 1000,
      });
      navigate(APP_ROUTES.DASHBOARD, { replace: true });
    }
  }, [
    location.pathname,
    selectedOrgInfo?.orgResourceName,
    user?.orgInfos,
    dispatch,
    navigate,
  ]);

  return (
    <Box height={'100%'}>
      <title>`&quot;Login | Orby-UI&quot;</title>
      {isIdleSessionManagerEnabled && (
        <IdleSessionManager
          overrideSessionTimeoutTo1Min={overrideSessionTimeoutTo1Min}
        />
      )}
      <Box height={'100%'} display={'flex'}>
        <WebDrawerWrapper
          collapse={collapse}
          setCollapse={setCollapse}
          orbotWorkflowLength={orbotWorkflowLength}
        />
        <SessionTimeoutManager />
        <AnnouncementPopup announcements={user?.announcements ?? []} />
        <Box
          id={'main-content'}
          sx={{
            width: `calc(100% - ${collapse ? `${DRAWER_WIDTH_COLLAPSED}px` : `${DRAWER_WIDTH_EXPANDED}px`})`,
            marginLeft: collapse
              ? `${DRAWER_WIDTH_COLLAPSED}px`
              : `${DRAWER_WIDTH_EXPANDED}px`,
            transition: 'width 0.3s ease, margin-left 0.3s ease',
          }}
        >
          <Routes>
            {PROTECTED_ROUTES.map(
              (route) =>
                route.enabled && (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <SuspenseLoader>
                        <TrackedRoute routerPath={route.path}>
                          {route.element}
                        </TrackedRoute>
                      </SuspenseLoader>
                    }
                  />
                ),
            )}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

const LegacyTaskReviewRedirect: FC = () => {
  const { workflow_id, task_id } = useParams();
  const orgId = useSelector(selectedOrgIdSelector);

  if (!orgId) {
    return null; // or loading state if needed
  }

  return (
    <Navigate
      to={`/organisations/${orgId}/workflows/${workflow_id}/tasks/${task_id}/automation-review`}
      replace
    />
  );
};

export default memo(ProtectedRoutes);
