import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateApiKeyRequest,
  ListApiKeysRequest,
} from 'protos/pb/v1alpha1/organization_service';
import { ApiKey } from 'protos/pb/v1alpha1/api_key';
import { DataLoadingStatus } from '../../utils/constants';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';

export interface ApiKeysState {
  list: ApiKey[];
  listStatus: DataLoadingStatus;
  createStatus: DataLoadingStatus;
  listError: string | null;
  createError: string | null;
  createdKey: string | null;
  encryptedKey: string | null;
  pagination: {
    pageSize: number;
    pageNumber: number;
    totalSize: number;
  };
}

const initialState: ApiKeysState = {
  list: [],
  listStatus: DataLoadingStatus.INITIAL,
  createStatus: DataLoadingStatus.INITIAL,
  listError: null,
  createError: null,
  createdKey: null,
  encryptedKey: null,
  pagination: {
    pageSize: DEFAULT_ROWS_PER_PAGE,
    pageNumber: DEFAULT_FIRST_PAGE,
    totalSize: 0,
  },
};

// Replace the manual action creation with proper createAction
export const createApiKey = createAction<CreateApiKeyRequest>(
  'apiKeys/createApiKey',
);
export const listApiKeys = createAction<ListApiKeysRequest>(
  'apiKeys/listApiKeys',
);

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    listApiKeysSuccessAction: (
      state,
      action: PayloadAction<{ apiKeys: ApiKey[]; totalSize: number }>,
    ) => {
      state.listStatus = DataLoadingStatus.LOADED;
      state.list = action.payload.apiKeys;
      state.listError = null;
      state.pagination.totalSize = action.payload.totalSize;
    },
    listApiKeysFailureAction: (state, action: PayloadAction<string>) => {
      state.listStatus = DataLoadingStatus.ERROR;
      state.listError = action.payload;
    },
    createApiKeySuccessAction: (state, action: PayloadAction<string>) => {
      state.createStatus = DataLoadingStatus.LOADED;
      state.encryptedKey = action.payload;
      state.createError = null;
    },
    createApiKeyFailureAction: (state, action: PayloadAction<string>) => {
      state.createStatus = DataLoadingStatus.ERROR;
      state.createError = action.payload;
    },
    resetCreateApiKeyStatus: (state) => {
      state.createStatus = DataLoadingStatus.INITIAL;
      state.createError = null;
      state.createdKey = null;
      state.encryptedKey = null;
    },
    setPagination: (
      state,
      action: PayloadAction<{ pageNumber: number; pageSize: number }>,
    ) => {
      state.pagination.pageNumber = action.payload.pageNumber;
      state.pagination.pageSize = action.payload.pageSize;
    },
    setDecryptedKey: (state, action: PayloadAction<string>) => {
      state.createdKey = action.payload;
    },
    clearDecryptedKey: (state) => {
      state.createdKey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listApiKeys, (state) => {
        state.listStatus = DataLoadingStatus.LOADING;
        state.listError = null;
      })
      .addCase(createApiKey, (state) => {
        state.createStatus = DataLoadingStatus.LOADING;
        state.createError = null;
      });
  },
});

export const {
  listApiKeysSuccessAction,
  listApiKeysFailureAction,
  createApiKeySuccessAction,
  createApiKeyFailureAction,
  resetCreateApiKeyStatus,
  setPagination,
  setDecryptedKey,
  clearDecryptedKey,
} = apiKeysSlice.actions;

// Export selectors
export const apiKeysListStatusSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.listStatus;
export const apiKeysCreateStatusSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.createStatus;
export const apiKeysCreatedKeySelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.createdKey;
export const apiKeysListSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.list;
export const apiKeysListErrorSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.listError;
export const apiKeysCreateErrorSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.createError;
export const apiKeysPaginationSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.pagination;
export const apiKeysEncryptedKeySelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.encryptedKey;
export default apiKeysSlice.reducer;
